import { FETCH_YOUTUBE, FETCH_YOUTUBE_FAILURE, FETCH_YOUTUBE_SUCCESS } from "actions/sns.action";
import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";
import { produce } from "immer";

const INITIAL_STATE = {
    status: CLEAR,
    error: false,
    data: {},
    items: null,
    nextPageToken: null,
};

const snsYoutubeReducer = produce((state, action) => {
    switch (action.type) {
        // 유튜브 리스트 조회 시작
        case FETCH_YOUTUBE:
            state.status = LOADING;
            break;

        // 유튜브 리스트 조회 성공
        case FETCH_YOUTUBE_SUCCESS:
            state.status = SUCCESS;
            state.nextPageToken = action.payload.nextPageToken;
            state.items = state.items && state.items.length > 0 ? state.items.concat(action.payload.items) : action.payload.items;
            return;

        // 유튜브 리스트 조회 실패
        case FETCH_YOUTUBE_FAILURE:
            state.status = ERROR;
            state.error = action.payload;
            return;

        default:
            return;
    }
}, INITIAL_STATE);

export default snsYoutubeReducer;
