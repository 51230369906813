import { RES_AFREECATV, STATION_SSL_URL } from "constant/config";

//젬 받기 url : 추후에 쓰일 수 있어서 주석처리
// export const GEM_QUEST_URL = `${ESPORTS_URL}/gempoint/quest/all`;

export const ERROR_IMG = `${RES_AFREECATV}/new_player/items/adballoon/ceremony/mobile_0_1.png`;

export const ACHIVEMENT_MODAL_IMG_PC =`${RES_AFREECATV}/images/adcon/gift_bjgoods.png`;
export const ACHIVEMENT_MODAL_IMG_MOBILE =`${RES_AFREECATV}/images/adcon/gift_bjgoods_m.png`;

export const ITEM_NAME = `BJ굿즈`;

export function EventImgPC(bj_id) {
    return `${RES_AFREECATV}/images/adcon/gift_${bj_id}.jpg`;
}

export function EventImgMobile(bj_id) {
    return `${RES_AFREECATV}/images/adcon/gift_${bj_id}_m.jpg`;
}

function EventUrl(bj_id, post_num){
    return `${STATION_SSL_URL}/${bj_id}/post/${post_num}`;
}

export const EVENT_INFO = {
    //o감동란o
	"xiaoeun": {
        item : ITEM_NAME,
        info_url : EventUrl("xiaoeun", "92155065")
    },

    //엔돌핀♥
    "dmsgkdn12": {
        item : ITEM_NAME,
        info_url : EventUrl("dmsgkdn12", "92122697")
    }
};