import { fetchInstagramFollowed, fetchYoutubeFollowed } from "actions/sns.action";
import classNames from "classnames";
import ConditionWrapper from "components/ConditionWrapper";
import useSns from "components/hook/useSns";
import { displayNumber } from "helpers";
import React, { useCallback, useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

export default function SnsLink() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { instagram, youtube, isInstagramAd, isYoutubeAd } = useSns();
    const youtubeChannelId = useMemo(() => {
        return youtube ? youtube.channel_id : null;
    }, [youtube]);
    const [instagramChannelId, instagramId] = useMemo(() => {
        return instagram ? [instagram.channel_id, instagram.id] : [null, null];
    }, [instagram]);
    /*
    방송국 홈 타이틀 영역에서의 인스타그램 팔로워 및 유튜브 구독자수 노출로직
    유저 문의로 인한 롤백 가능성이 있어 히든처리
    //
    // 유투브 팔로워 수
    //
    useEffect(() => {
        if (youtubeChannelId) {
            dispatch(fetchYoutubeFollowed(youtubeChannelId));
        }
    }, [dispatch, youtubeChannelId]);

    //
    // 인스타그램 팔로워 수
    //
    useEffect(() => {
        if (instagramId) {
            dispatch(fetchInstagramFollowed(instagramId, instagramChannelId));
        }
    }, [dispatch, instagramChannelId, instagramId]);

    const handleInstagramClick = useCallback(() => {
        history.push(`/${global.bj_id}/posts/instagram`);
    }, [history]);

    const handleYoutubeClick = useCallback(() => {
        history.push(`/${global.bj_id}/posts/youtube`);
    }, [history]);

    if (instagram || youtube) {
        return (
            <div className={classNames("bj-sns", { sns_connect: isInstagramAd || isYoutubeAd })}>
                <ConditionWrapper condition={isMobile} wrapper={<div className="top_line" />}>
                    <div className="innr">
                        {instagram && (
                            <button type="button" className="btn-instagram" onClick={handleInstagramClick}>
                                <em className="logo instagram"></em>
                                <strong>{instagram.title}</strong>
                                {instagram.followers > 0 && (
                                    <span>
                                        {t("팔로워")} {displayNumber(instagram.followers)}
                                    </span>
                                )}
                            </button>
                        )}
                        {youtube && (
                            <button type="button" className={classNames({ "btn-youtube": isMobile })} onClick={handleYoutubeClick}>
                                <em className="logo youtube"></em>
                                <strong>{youtube.title}</strong>
                                {youtube.followers > 0 && (
                                    <span>
                                        {t("구독자")} {displayNumber(youtube.followers)}
                                    </span>
                                )}
                            </button>
                        )}
                    </div>
                </ConditionWrapper>
            </div>
        );
    } else {
        return null;
    }
    */
    return null;
}