// 방명록
export const GUEST = 101;
// 편성
export const SCHEDULE = 102;
// 사진
export const PHOTO = 103;
// 일반
export const NORMAL = 104;
// UCC
export const UCC = 105;
// UCC_LIST
export const UCC_LIST = 115;
// 한줄
export const LINE = 106;
// 제목
export const TITLE = 107;
// 방송다시보기
export const REVIEW = 108;
// 방송 하일라이트
export const HIGHLIGHT = 109;
// 편집 영상
export const EDITOR = 105;
// 댓글
export const PARENT_COMMENT = 203;
// 대댓글
export const CHILD_COMMENT = 204;
// 좋아요
export const LIKE = 205;
// <Trans>신고하기</Trans>
export const REPORT = 206;

// 인스타그램
export const INSTAGRAM = 900;
// 유튜브
export const YOUTUBE = 901;

// 방송국 선물 게시판
export const BJSUPPORT = 112;