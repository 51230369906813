import i18next from "i18next";
import { RES_AFREECATV } from "constant/config";

export const AWARD = "AWARD";

//메달 이미지 다국어 대응
export const getBasicMedalImg = (lang,medalName="") => {
    return (lang=='ko')
            ? RES_AFREECATV+"/images/medal/basic/"+medalName.toLowerCase()+".svg"
            : RES_AFREECATV+"/images/medal/basic/lang/"+medalName.toLowerCase()+".svg";
}

//메달 이미지 다국어 대응
export const getSimpleMedalImg = (lang,medalName="") => {
    return (lang=='ko') 
    ? RES_AFREECATV+"/images/medal/simple/"+medalName.toLowerCase()+".png" 
    : RES_AFREECATV+"/images/medal/simple/lang/"+medalName.toLowerCase()+".png";
}

export const getMedalDescription = (medalName="",medalDescription) => {
    if(medalName.includes(AWARD)){
        const AWARD_YEAR = medalName.split('AWARD')[1].slice(0,4);//수상년도 추출

        return i18next.t("{{award_year}}년을 빛낸 BJ 대상 수상 BJ입니다.",{award_year : AWARD_YEAR});
    }
    return i18next.t(medalDescription);
}

export const getMedalName = (medalName="") => {
    if(medalName.includes("대상 BJ")){
        const AWARD_YEARd = medalName.slice(0,4);//수상년도 추출

        return i18next.t("{{award_year}} BJ 대상 BJ",{award_year : AWARD_YEARd});
    }
    return i18next.t(medalName);
}