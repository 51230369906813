import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { DEAPI_URL } from "constant/ad";

export const FETCH_AD = "FETCH_AD";
export const FETCH_AD_SUCCESS = "FETCH_AD_SUCCESS";
export const FETCH_AD_FAILURE = "FETCH_AD_FAILURE";
export const CLEAR_AD = "CLEAR_AD";

// 광고API 호출
export function fetchAd(params, code) {
    return (dispatch) => {
        dispatch({ type: FETCH_AD });

        fetch(`${DEAPI_URL}/api/v1/recommend?${params}`, {
            method: "GET",
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                if (response.exist) {
                    if (response.type === "ERROR") {
                        dispatch({
                            type: FETCH_AD_FAILURE,
                            payload: response,
                        });
                    } else {
                        let creatives = {};
                        if (response.type !== "SDK") {
                            creatives = response.creatives.find((element) => element.impression_url !== "") || {};

                            //creatives에서 impression_url은 없고 impression_urls만 있는 경우때문에 조건 처리 
                            const impressionUrl = (creatives.impression_url !== undefined) ? creatives.impression_url : creatives.impression_urls[0];

                            adSetlog(impressionUrl);
                        }
                        dispatch({
                            type: FETCH_AD_SUCCESS,
                            placement: code,
                            creatives: creatives,
                            payload: response,
                        });
                    }
                } else {
                    dispatch({
                        type: FETCH_AD_FAILURE,
                        payload: response,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_AD_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

// 광고 통계 호출
export function adSetlog(url) {
    if (typeof url !== "undefined" && url !== "") {
        fetch(url, {
            credentials: "include",
        }).then(() => {});
    }
}
