
export const HOME = "HOME"; // 방송국 홈
export const HOME_USER_VOD = "HOME_USER_VOD" // 방송국 홈 유저 VOD
export const SETTING = "SETTING"; // 방송국 설정
export const LNB_PROFILE = "LNB_PROFILE"; // 방송국 LNB
export const SETTING_LNB_PROFILE = "SETTING_LNB_PROFILE"; // 방송국 설정 LNB

export const STARBALLOON = "STARBALLOON"; // LNB 및 모바일 정보 하단 열혈팬 리스트
export const SUPPORTER = "SUPPORTER"; // LNB 및 모바일 정보 하단 서포터 리스트
export const POST_PROFILE = "POST_PROFILE"; // 글 영역

export const COMMENT_WRITE_PROFILE = "COMMENT_WRITE_PROFILE" // 댓글 쓰기 목록

export const COMMENT_PROFILE = "COMMENT_PROFILE"; // 댓글 목록

export const GUEST_WRITE_PROFILE = "GUEST_WRITE_PROFILE"; //방명록 쓰기 목록

export const GUEST_PROFILE = "GUEST_PROFILE"; //방명록 목록

export const MUST_JPG = "MUST_JPG"; // 포지션과 상관없이 예외적으로 고화질때문에 선명하게 처리하기 위함

export const PREVIEW_IMG = "PREVIEW_IMG"; //미리보기 영역