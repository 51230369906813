import { RES_AFREECATV, STATION_URL } from "constant/config";
import {
    ADMIN_DELETE,
    BJ_HIDDEN,
    ENCODING,
    ENCODING_FAIL,
    ENCODING_WAIT,
    FILTERING,
    SUCCEED,
    TIMEOUT_DELETE,
    UPLOADING,
    UPLOAD_FAIL,
} from "constant/video.status";
import {
    PROFILE_PREVIEW,
    PROFILE_SAVE,
} from "constant/image.load";
import {
    HOME,
    HOME_USER_VOD,
    SETTING,
    LNB_PROFILE,
    STARBALLOON,
    SUPPORTER,
    POST_PROFILE,
    COMMENT_PROFILE,
    GUEST_PROFILE,
    COMMENT_WRITE_PROFILE,
    GUEST_WRITE_PROFILE,
    MUST_JPG,
    PREVIEW_IMG,
    SETTING_LNB_PROFILE
} from "constant/position.path";

import { DEFAULT_THUMBNAIL_IMAGE_URL, handleThumbnailOnerror } from "listeners/error.listener";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import {sendNote, getProfileImage, getProfileImage2} from "helpers";
import {useSelector} from "react-redux";
import { CATCHSTORY } from "constant/filetype";


global.dummyTime = new Date().getTime();

/**
 * 게시판이미지
 * @param {*} props
 */
export const BoardImage = (props) => {
    return <img {...props} src={props.src} alt="" onError={handleThumbnailOnerror()} loading="lazy" />;
};

/**
 * 게시판이미지
 * @param {*} props
 */
export const VideoImage = ({ vod }) => {
    const { ucc = {}, auth_no, copyright, is_reserve } = vod;
    const { flag = SUCCEED, grade, category } = ucc;
    const isAdult = grade === 19 && parseInt(category) === 30000; //성인여부
    const [imgError, setImgError] = useState(false);

    const handleVodImageError = (e) => {
        setImgError(true);
    };

    switch (flag) {
        // 임시 적용 - 추후 변경해야됨 : 영상이 권한에 안맞을 경우 기본 썸네일 노출
        case "AUTH_DENIED":
            return <img src={DEFAULT_THUMBNAIL_IMAGE_URL} alt="" loading="lazy" />;

        case TIMEOUT_DELETE:
            return <img src={`${RES_AFREECATV}/images/mobile/mybs/img_expire.jpg`} alt="" loading="lazy" />;

        case ENCODING_FAIL:
            return(
                <span className="thumb-encoding">
                    <em><Trans>인코딩 실패</Trans></em>
                </span>
            );
        case FILTERING:
        case UPLOAD_FAIL:
            return(
                <span className="thumb-upload">
                    <em><Trans>업로드 실패</Trans></em>
                </span>
            );
        case ADMIN_DELETE:
            return(
                <span className="thumb-del">
                    <em><Trans>운영자 삭제</Trans></em>
                </span>
            );
        case ENCODING:
        case ENCODING_WAIT:
        case UPLOADING:
            return(
                <span className="thumb-converting">
                    <em><Trans>VOD 변환중</Trans></em>
                </span>
            );

        case BJ_HIDDEN:
            return isAdult === true ? (
                <span className="thumb-lock_adult">
                    <Trans>19금 + 비번방</Trans>
                </span>
            ) : (
                <span className="thumb-lock">
                    <Trans>비번방</Trans>
                </span>
            );

        case SUCCEED:
        default:
            if (copyright && copyright.del_flag === "Y") {
                return (
                    <div className="thumb-text">
                        <p>
                            <Trans>방송한 BJ에 의해 블라인드 처리된 VOD 입니다</Trans>
                        </p>
                    </div>
                );
            } else {
                if (auth_no === 102 && is_reserve !== true) {
                    //비공개
                    return isAdult === true ? (
                        <span className="thumb-lock_adult">
                            <Trans>19금 + 비번방</Trans>
                        </span>
                    ) : (
                        <span className="thumb-lock">
                            <Trans>비번방</Trans>
                        </span>
                    );
                } else if (isAdult === true) {
                    return (
                        <span className="thumb-adult">
                            <Trans>19금</Trans>
                        </span>
                    );
                } else {
                    if (imgError) {
                        return (
                            <span className="thumb-default">
                                <Trans>썸네일</Trans>
                            </span>
                        );
                    } else {
                        let thumb = ucc.thumb;
                        // 캐치스토리
                        if(ucc.file_type=== CATCHSTORY ){
                            //thumb_type이 SUCCEED가 아닌 경우
                            if(ucc.thumb_type !== SUCCEED){
                                return(
                                    <span className="thum">
                                        <img src={ucc.thumb_h} alt="" onError={handleVodImageError} loading="lazy"/>
                                    </span>
                                )
                            }
                            if(ucc.resolution_type === 'horizontal'){
                                // 가로형
                                return (
                                    <span className="thum">
                                        <img src={ucc.thumb_h} alt="" onError={handleVodImageError} loading="lazy"/>
                                    </span>
                                );
                            } else {
                                // 세로형
                                return (
                                    <span className="thum thumb_vt">
                                        <img src={ucc.thumb} alt="" onError={handleVodImageError} loading="lazy"/>
                                        <div className="thumb_blur">
                                            <img src={ucc.thumb} alt="" loading="lazy"/>
                                        </div>
                                    </span>
                                );
                            }
                        }
                        
                        if(typeof thumb === 'string') {
                            thumb = thumb.replace(/(_r)/g, "_l");
                        }

                        return <img src={thumb} alt="" onError={handleVodImageError} loading="lazy" />;
                    }
                }
            }
    }
};

// 새로만들자 ㅠㅠ
export const ProfileImage2 = ({userId, src, className = "thumb", imageLoadType = false, position = "", onChange = false }) => {

    /**
     * 1. JPG > default , 2. webp > jpg > default , 3. webp > default, 4. webp ??? > jpg > default
     * webp가 확장지로 들어올 경우에는 onError 체크가 두번 들어가야 한다
     *
     * <div class="thum">
     *     <img src="//profile.img.afreecatv.com/LOGO/yu/yuhedev42/yuhedev42.jpg" alt="">
     *     <button type="button" class="msg" tip="쪽지 보내기"><span>쪽지</span></button> (쪽지보내기 유무)
     * </div>
     */

    const { t } = useTranslation();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const { is_login, user_id:login_id } = authenticationReducer;
    const imgRef = useRef(null);
    const targetIsMe = (login_id === userId);
    const dummyParam = targetIsMe ? "?dummy=" + global.dummyTime  : ""; // 본인 방송국일 경우 설정에서 이미지를 변경할 수 있으므로 dummy를 붙인다.
    //방송국 이동 설정 조건
    const handleGoStation = (event) => {
        switch (position) {

            //그냥 새탭 이동 지원 안되는 곳 [방송국 홈 LNB 프로필, 방송국 설정 LNB 프로필, 방송국 설정 미리보기, 댓글 글쓰기 폼]
            case LNB_PROFILE:
            case COMMENT_WRITE_PROFILE:
            case GUEST_WRITE_PROFILE:
            case PREVIEW_IMG:
            case SETTING_LNB_PROFILE:
                return false;
            /**
             * 새탭 이동 조건
             *
             * 2. 본인일 경우 제한 걸어야 하는 케이스
             *    - 설정 페이지, 열혈팬/서포터 리스트
             *    - 방송국 관리 본문 하단 제작 BJ 영역
             *    - 댓글 작성자 프로필
             *    - 설정 유저 리스트가 노출 되는 영역
             * 3. 게시글 (본인일 경우 제한 없음)
             */
            case HOME_USER_VOD:
            case SETTING:
            case STARBALLOON:
            case SUPPORTER:
                if (targetIsMe) {
                    return false;
                }
            case COMMENT_PROFILE:
            case POST_PROFILE:
            case GUEST_PROFILE:
                goStation(event);
                break;
            default:
                goStation(event);
        }
        return false;
    }

    // 방송국 가기
    const goStation = useCallback((event) => {
        event.preventDefault();
        window.open(`${STATION_URL}/${userId}`);
    }, [userId]);

    
    //쪽지 보내기 - 홈 BJ 프로필에만 적용
    const handleSendNote = useCallback((event) => {
        event.preventDefault();
        sendNote(global.bj_id);
    }, []);
    
    // 쪽지 보내기 노출
    const noteDom = useMemo(() => {
        // 설정 페이지 아니고, 본인이 아닌 경우 LNB 프로필 쪽지 이미지 노출
        if (position !== SETTING && position === LNB_PROFILE && !targetIsMe) {
            return (
                <button type="button" className="msg" tip={t("쪽지 보내기")} onClick={handleSendNote}>
                <span>
                    <Trans>쪽지</Trans>
                </span>
                </button>
            )
        }

    }, [handleSendNote, targetIsMe, position, t]);

    const handleImgLoadFail = useCallback((e) => {



        if (imgRef.current.src.indexOf('webp') != -1) {
            imgRef.current.src = getProfileImage2(userId, "JPG", dummyParam); // ex) //stimg.afreecatv.com/LOGO/sa/sabulapa/sabulapa.jpg;
        }
        else {
            imgRef.current.src = getProfileImage2(userId, "default", dummyParam); // ex) //res.afreecatv.com/images/afmain/img_thumb_profile.gif
        }
    }, [dummyParam, userId]);

    // 이미지 만들기 dom
    const imgTagDom = useMemo(() => {

        const defaultTarget = [COMMENT_WRITE_PROFILE, GUEST_WRITE_PROFILE];
        const jpgTarget = [LNB_PROFILE, MUST_JPG, PREVIEW_IMG];


        // 로그인 안했을 경우 default form
        if (!is_login && (defaultTarget.indexOf(position) != -1)) {
            return <img src={`${RES_AFREECATV}/images/afmain/img_thumb_profile.gif`} alt=""/>;
        }
        if (onChange) {
            global.dummyTime = new Date().getTime();
            return <img src={src} ref={imgRef} onError={handleImgLoadFail} alt=""/>;

        }
        // 임시라 삭제해야됨
        else if (position === HOME_USER_VOD) {
            return (
                <img src={getProfileImage2(userId, "WEBP_SMALL", dummyParam)} ref={imgRef} onError={(e) => handleImgLoadFail(e)}
                     style={ {width: "42px", height: "42px", borderRadius:"50%", zIndex:10, cursor:"pointer" } } alt=""/>
            );
        } else {
            return (
                <img src={jpgTarget.indexOf(position) != -1 ?
                    getProfileImage2(userId, "JPG", dummyParam)
                    :getProfileImage2(userId, "WEBP_SMALL", dummyParam)} ref={imgRef} onError={(e) => handleImgLoadFail(e)} alt=""/>
            );
        }

    }, [dummyParam, handleImgLoadFail, position, userId, onChange]);


    return (
        <div
            className={className}
            onClick={handleGoStation}>
                {imgTagDom}
                {noteDom}
        </div>
    );
}


// 미사용 하도록 변경
export const ProfileImage = ({userId, src, className = "thumb", noteCheck = false, ext = "webp", isHome=false}) => {
    // webp image set
    
    const [profile, setProfile] = useState('');
    const defaultImg = `${RES_AFREECATV}/images/afmain/img_thumb_profile.gif`;

    const { t } = useTranslation();
    /**
     * image Check 
     */
    useEffect(() => {
        let loading = true;

        if(!userId) {
            loading = false;
            setProfile(defaultImg);
        } else {
            // webp
            let profileImg = '';
            if (ext === "jpg") {
                profileImg = getProfileImage(userId, true);
            } else {
                profileImg = getProfileImage(userId);
            }

            let image = new Image();
            image.src = profileImg;
    
            let jpgProfile = getProfileImage(userId, true);
    
            /**
             * webp load 성공적이면 onload webp
             */
            image.onload = () => {
                if(loading) {
                    setProfile(profileImg);
                }
            }
    
            /**
             * webp load 실패 할 경우 onerror webp->jpg
             */
            image.onerror = () => {
                if(loading) {
                    setProfile(jpgProfile);
                }
            }
        }


        return () =>{
            loading = false;
        }
        
    }, [userId, setProfile]);

    /**
     * 방송국 이동
     */

    const handleGoStation = (event) => {
        if (!isHome) {
            event.preventDefault();
            window.open(`${STATION_URL}/${userId}`);
        }


        return false;
    }

    /**
     * 쪽지 보내기 - 홈 BJ 프로필에만 적용
     */
    const handleSendNote = () => {
        sendNote(global.bj_id);
    }

    const noteDom = useMemo(() => {
        return (
            <button type="button" className="msg" tip={t("쪽지 보내기")} onClick={handleSendNote}>
                <span>
                    <Trans>쪽지</Trans>
                </span>
            </button>
        )
    }, []);

    return (
        <div 
            className={className} 
            style={{backgroundImage: `url(${profile})`}}
            onClick={handleGoStation}>
                {noteCheck && noteDom}
        </div>
    )

}
