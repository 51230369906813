import produce from "immer";

import { FETCH_SHOPFREECA, FETCH_SHOPFREECA_LIST_SUCCESS, FETCH_SHOPFREECA_LIST_FAILURE } from "actions/shopfreeca.list.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
    status: CLEAR,
    error: null,
    pagination: {
        data: [],
        links: {},
        meta: {},
    },
};


const shopfreecaListReducer = produce((state, { type, payload }) => {
    switch (type) {
        // 샵프리카 리스트 로딩 시작
        case FETCH_SHOPFREECA:
            state.status = LOADING;
            state.key = payload.key;
            state.append = payload.append;
            return;

        // 샵프리카 리스트 조회 성공
        case FETCH_SHOPFREECA_LIST_SUCCESS:
            state.status = SUCCESS;

            const pagination = payload;

            if (state.append) {
                pagination.data = [...state.pagination.data, ...payload.data];
            }



            state.pagination = {
                data: pagination.data,
                links: pagination.links,
                meta: pagination.meta,
            };
            return;

        // 샵프리카 리스트 조회 실패
        case FETCH_SHOPFREECA_LIST_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        default:
    }
}, INITIAL_STATE);

export default shopfreecaListReducer;
