import React, { useCallback, useState, useEffect } from 'react';
import cookie from "react-cookies";
import { THEME_COOKIE_NAME, DARKMODE_CLASS_NAME, DARKMODE_CLASS_VALUE } from 'constant/theme';
import { AFREECATV_DOMAIN, VOTE_PROTOCAL_URL } from 'constant/config';
import { setClickLogapi } from 'helpers';
import { getVoteIFrames } from 'libs/VoteFrame';
import useCookie from "components/hook/useCookie";


const DarkMode = () => {

    const [darkMode, setDarkMode] = useState(false);
    const [darkModeCookie, setDarkModeCookie, removeDarkModeCookie] = useCookie('theme','');


    useEffect(() => {
        const iframes = getVoteIFrames();
        try {
            for (let index in iframes) {
                iframes[index].postMessage({ action: "darMode", value: darkMode }, VOTE_PROTOCAL_URL);
            }

            //CKEDITOR 다크모드설정
            if (window.CKEDITOR && window.CKEDITOR.instances) {
                for (let index in window.CKEDITOR.instances) {
                    if (darkMode) {
                        window.CKEDITOR.instances[index].document.getBody().$.classList.add("thema_dark");
                    } else {
                        window.CKEDITOR.instances[index].document.getBody().$.classList.remove("thema_dark");
                    }
                }
            }
        } catch (e) { }

    }, [darkMode]);

    /**
     * componentDidMount
     */
    useEffect(() => {
        const theme = cookie.load("theme");
        setDarkMode(theme === 'dark')

    }, []);



    const handleDarkModeChange = useCallback((e) => {

        if (e.target.checked) {
            setClickLogapi("conf_00000001", { theme_color: 'dark' });
            const expires = new Date();
            expires.setFullYear(2038, 0, 1);
            
            setDarkModeCookie(
                DARKMODE_CLASS_NAME,{
                    domain: AFREECATV_DOMAIN,
                    path: "/",
                    expires: expires
                }
            )
            //cookie.save(THEME_COOKIE_NAME, DARKMODE_CLASS_NAME, { domain: AFREECATV_DOMAIN, path: "/", expires });
            document.body.classList.add(DARKMODE_CLASS_VALUE);
            setDarkMode(true)
        } else {
            setClickLogapi("conf_00000001", { theme_color: 'light' });
            //cookie.remove(THEME_COOKIE_NAME, { domain: AFREECATV_DOMAIN, path: "/" });
            removeDarkModeCookie(
                {
                    domain: AFREECATV_DOMAIN,
                    path: "/"
                }
            );
            document.body.classList.remove(DARKMODE_CLASS_VALUE);
            setDarkMode(false)
        }
    }, []);


    return (
        <div className="modeSet">
            <div className="settings">
                <strong className="my_mode">어두운모드</strong>
                <input type="checkbox" id="modecheck" checked={darkMode} onChange={handleDarkModeChange} />
                <label className="modecheck" htmlFor="modecheck"></label>
                <p>이 브라우저에만 적용됩니다</p>
            </div>
        </div>
    );
};
export default DarkMode;
