import store from "store";
import i18next from "i18next";
import querystring from "querystring";

import { goLogin } from "helpers";

import axios from "libs/axios";

import { STBBS_URL } from "constant/config";

import { toastr } from "components/toastr";

export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const DELETE_PLAYLIST_SUCCESS = "DELETE_PLAYLIST_SUCCESS";

/**
 * 재생목록 퍼가기
 * @param playlistIdx
 * @returns {Promise<void>}
 */
export const embedPlaylist = async (playlistIdx) => {
	const { is_login } = store.getState().authenticationReducer;
	if (!is_login && window.confirm(i18next.t("로그인이 필요합니다. 로그인 하시겠습니까?"))) {
		goLogin();
		return;
	}

	// 재생목록 퍼가기 api 호출
	const data = {
		szWork: "share_playlist_bj_list",
		nPlaylistIdx: playlistIdx,
	};

	try {
		const { result, msg } = await axios.post(`${STBBS_URL}/vodplaylist/api/api.php`, querystring.stringify(data));

		if (result === 1) {
			// 재생목록 퍼가기 성공
			toastr.success(msg, {
				timeOut: 2000,
			});
		} else {
			// 재생목록 퍼가기 실패
			toastr.warning(msg, {
				timeOut: 2000,
			});
		}
	} catch (error) {
		// 알 수 없는 오류 발생
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 퍼온 재생목록 삭제
 * @param playlistIdx
 * @returns {function(...[*]=)}
 */
export const deleteEmbededPlaylist = (playlistIdx) => async (dispatch) => {
	if (!window.confirm(i18next.t("재생목록을 삭제하시겠습니까?"))) {
		return;
	}

	dispatch({ type: DELETE_PLAYLIST });

	// 퍼간 재생목록 삭제 api 호출
	const data = {
		szWork: "share_playlist_bj_del_list",
		nPlaylistIdx: playlistIdx,
	};

	try {
		const { result, msg } = await axios.post(`${STBBS_URL}/vodplaylist/api/api.php`, querystring.stringify(data));

		if (result === 1) {
			// 퍼간 재생목록 삭제 성공
			dispatch({
				type: DELETE_PLAYLIST_SUCCESS,
				payload: { playlist_idx: playlistIdx },
			});

			toastr.success(msg, {
				timeOut: 2000,
			});
		} else {
			// 퍼간 재생목록 삭제 실패
			toastr.warning(msg, {
				timeOut: 2000,
			});
		}
	} catch (error) {
		// 알 수 없는 오류 발생
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 생성한 재생목록 삭제
 * @param playlistIdx
 * @returns {function(...[*]=)}
 */
export const deletePlaylist = (playlistIdx) => async (dispatch) => {
	if (!window.confirm(i18next.t("재생목록을 삭제하시겠습니까?"))) {
		return;
	}

	dispatch({ type: DELETE_PLAYLIST });

	// 재생목록 삭제 api 호출
	const data = {
		szWork: "del_playlist_list",
		nPlaylistIdx: playlistIdx,
	};

	try {
		const { result, msg } = await axios.post(`${STBBS_URL}/vodplaylist/api/api.php`, querystring.stringify(data));

		if (result === 1) {
			// 재생목록 삭제 성공
			dispatch({
				type: DELETE_PLAYLIST_SUCCESS,
				payload: { playlist_idx: playlistIdx },
			});

			toastr.success(msg, {
				timeOut: 2000,
			});
		} else {
			// 재생목록 삭제 실패
			toastr.warning(msg, {
				timeOut: 2000,
			});
		}
	} catch (error) {
		// 알 수 없는 오류 발생
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};
