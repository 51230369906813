import axios from "libs/axios";
import { ROOT_URL } from "actions/config";
import qs from "qs";
import {toastr} from "../../components/toastr";
import i18next from "i18next";
import {SELECTED_ALL} from "../../constant/parameter";

export const FETCH_SETTING_PLAYLIST_LIST = 'FETCH_SETTING_PLAYLIST_LIST';
export const FETCH_SETTING_PLAYLIST_LIST_SUCCESS = 'FETCH_SETTING_PLAYLIST_LIST_SUCCESS';
export const FETCH_SETTING_PLAYLIST_LIST_FAIL = 'FETCH_SETTING_PLAYLIST_LIST_FAIL';

export const UPDATE_SETTING_PLAYLISTS = "UPDATE_SETTING_PLAYLISTS";
export const FETCH_SETTING_PLAYLIST_LIST_ADD = 'FETCH_SETTING_PLAYLIST_LIST_ADD';
export const FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS = 'FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS';
export const FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS_MORE = 'FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS_MORE';
export const FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL = 'FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL';
export const FETCH_SETTING_PLAYLIST_RELOAD = 'FETCH_SETTING_PLAYLIST_RELOAD';
export const DELETE_SETTING_PLAYLISTS = 'DELETE_SETTING_PLAYLISTS';
export const DELETE_ALL_SETTING_PLAYLISTS = 'DELETE_ALL_SETTING_PLAYLISTS';

// 방송국 설정 > 재생목록 리스트 조회
export const fetchSettingPlaylist = () => async (dispatch) => {

    // 로딩 시작
    dispatch({ type: FETCH_SETTING_PLAYLIST_LIST });


    let url = `${ROOT_URL}/${global.bj_id}/setting/playlist`;

    try {
        // api 통신 시작
        const response = await axios.get(url);

        dispatch({
            type: FETCH_SETTING_PLAYLIST_LIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SETTING_PLAYLIST_LIST_FAIL,
            payload: error,
        });
    }
};

// 방송국 설정 > 재생목록 리스트 > 순서 저장 하기
export const storeSettingSortPlaylists = (param) => async (dispatch) => {
    let url = `${ROOT_URL}/${global.bj_id}/setting/playlist/sort`;

    try {
        const response = await axios.post(url, qs.stringify(param));
        toastr.success(i18next.t(response.message), {
            timeOut: 2000,
        });

    } catch (error) {
        dispatch({
            type: FETCH_SETTING_PLAYLIST_LIST_FAIL,
            payload: error,
        });
    }
}

// 방송국 설정 > 재생목록 '추가' 리스트

export const fetchSettingPlaylistAdd = ({page, orderBy}) => async (dispatch) => {
    // 재생목록 추가 리스트 조회 url
    let url = `${ROOT_URL}/${global.bj_id}/setting/playlist/add`;

    const params = {
        page: page,
        orderBy: orderBy,
    };

    try {
        // api 통신 시작
        const response = await axios.get(url, {params});

        dispatch({
            type: page > 1 ? FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS_MORE : FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS,
            payload: response,
            page:page,
        });

    } catch (error) {
        dispatch({
            type: FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL,
            payload: error,
        });
    }
};

// 방송국 설정 > 재생목록 관리 > BJ가 재생목록 저장 시 API 요청
export const storePlaylistAdd = (param, storeArr) => async (dispatch) => {

    let url = `${ROOT_URL}/${global.bj_id}/setting/playlist/add`;

    try {
        const response  = await axios.post(url, qs.stringify(param));
        await dispatch({ type: FETCH_SETTING_PLAYLIST_RELOAD, payload : storeArr });

        toastr.success(i18next.t(response.message), {
            timeOut: 2000,
        });

    } catch (error) {
        toastr.error(i18next.t(error.message), {
            timeOut: 2000,
        });

        dispatch({
            type: FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL,
            payload: error,
        });
    }
}

export const deletePlaylist = (type, playlistNo = 0) => async (dispatch) => {

    try {
        let response;
        if (type === SELECTED_ALL) {
            // 전체 삭제 (playlistNo == 0)
            response  = await axios.delete(`${ROOT_URL}/${global.bj_id}/setting/playlist/add/${playlistNo}`);
            await dispatch({ type: DELETE_ALL_SETTING_PLAYLISTS });

        } else {
            // 부분 삭제
            response  = await axios.delete(`${ROOT_URL}/${global.bj_id}/setting/playlist/${playlistNo}`);
            await dispatch({
                type: DELETE_SETTING_PLAYLISTS,
                payload: playlistNo,
            });
        }

        toastr.success(i18next.t(response.message), {
            timeOut: 2000,
        });

    } catch (error) {
        alert("재생목록 리스트 삭제 실패하였습니다");
        dispatch({
            type: FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL,
            payload: error,
        });
    }
}