import useInterval from "components/hook/useInterval";
import { LIVEIMG_URL } from "constant/config";
import React, { useCallback, useMemo, useState, useEffect  } from "react";
import { Trans } from "react-i18next";

const LiveImage = ({ broad, refresh }) => {
    const { broad_grade, is_password, broad_no } = broad;
    let [delay, setDelay] = useState(refresh ? 10000 : null);
    const [imgError, setImgError] = useState(false);

    const [broadThumbnail, setBroadThumbnail] = useState(`${LIVEIMG_URL}/h/${broad_no}.webp`);
    /**
     * event onerror로 처리 가능 하지만, 지속적으로 interval 진행 되면서 메모리 낭비 방지를 위해 로딩 될때 
     * 적용
     */

    useEffect(() => {
        let loading = true;

        if(broad_no) {
            let image = new Image();
            image.src = broadThumbnail;

            image.onerror = () => {
                if(loading) {
                    setBroadThumbnail(`${LIVEIMG_URL}/h/${broad_no}.jpg`);
                }
            }

            image.onload = () => {
                if(loading) {
                    setBroadThumbnail(`${LIVEIMG_URL}/h/${broad_no}.webp`);
                }
            }
        }
        return () => {
            loading = false;
        }
    }, [broad_no, setBroadThumbnail]);

    const defultBroadUrl = useMemo(() => {
        return broadThumbnail;
    }, [broadThumbnail]);

    useInterval(() => {
        setBroadThumbnail(`${defultBroadUrl}?${Math.random()}`);
    }, delay);

    const handleLiveImageError = useCallback((e) => {
        setImgError(true);
        setDelay(null);
    }, []);

    // 19금
    if (broad_grade === 19 && !is_password) {
        return (
            <span className="thumb-adult">
                <Trans>19금</Trans>
            </span>
        );
    }

    // 19금 + 비번방
    if (broad_grade === 19 && is_password) {
        return (
            <span className="thumb-lock_adult">
                <Trans>19금 + 비번방</Trans>
            </span>
        );
    }

    // 비번방
    if (broad_grade !== 19 && is_password) {
        return (
            <span className="thumb-lock">
                <Trans>비번방</Trans>
            </span>
        );
    }

    // default
    if (broad_grade !== 19 && !is_password) {
        if (imgError) {
            return (
                <span className="thumb-default">
                    <Trans>썸네일</Trans>
                </span>
            );
        } else {
            return <img src={broadThumbnail} alt="" onError={handleLiveImageError} />;
        }
    }
};

export default LiveImage;
