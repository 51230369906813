import React, { isValidElement } from "react";

const ConditionWrapper = ({ condition, children, wrapper, denyWrapper = null }) => {
	wrapper = condition ? wrapper : denyWrapper;

	let wrappedChildren;
	if (typeof wrapper === "string") {
		wrappedChildren = React.createElement(wrapper, {}, children);
	} else if (isValidElement(wrapper)) {
		wrappedChildren = React.cloneElement(wrapper, {}, children);
	} else {
		wrappedChildren = children;
	}

	return wrappedChildren;
};

export default ConditionWrapper;
