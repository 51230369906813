import { FETCH_INSTAGRAM, FETCH_INSTAGRAM_FAILURE, FETCH_INSTAGRAM_SUCCESS } from "actions/sns.action";
import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";
import { produce } from "immer";

const INITIAL_STATE = {
    status: CLEAR,
    error: false,
    data: {},
    items: null,
    paging: null,
};

const snsInstagramReducer = produce((state, action) => {
    switch (action.type) {
        // 인스타그램 리스트 조회 시작
        case FETCH_INSTAGRAM:
            state.status = LOADING;
            break;

        // 인스타그램 리스트 조회 성공
        case FETCH_INSTAGRAM_SUCCESS:
            state.status = SUCCESS;
            state.paging = action.payload.paging;
            state.items = state.items && state.items.length > 0 ? state.items.concat(action.payload.data) : action.payload.data;
            return;

        // 인스타그램 리스트 조회 실패
        case FETCH_INSTAGRAM_FAILURE:
            state.status = ERROR;
            state.error = action.payload;
            return;

        default:
            return;
    }
}, INITIAL_STATE);

export default snsInstagramReducer;
