import i18next from "i18next";
import axios from "libs/axios";
import { toastr } from "components/toastr";

import { ROOT_URL } from "./config";
export const FETCH_LINK = "FETCH_LINK";
export const FETCH_LINK_SUCCESS = "FETCH_LINK_SUCCESS";
export const FETCH_LINK_FAILURE = "FETCH_LINK_FAILURE";

export const MODIFY_LINK = "MODIFY_LINK";
export const DELETE_LINK = "DELETE_LINK";
export const UPDATE_LINK_SUCCESS = "UPDATE_LINK_SUCCESS";

export const fetchLink = async () => {
    try {
		const response = await axios.get(`/${global.bj_id}/setting/link`);
        return response.data;
    } catch (error) {
        toastr.error(error.message);
    }
};

export const putLink = async (datas) => {
	const defaultData = {
		no: 0,
		user_id: "",
		type: 0,
		link_name: "",
		url: "",
		image: null,
		reg_date: null
	};

    const res = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    let is_error = false;
	const banUrlList = ["twip.kr", "toss.me", "toon.at", "donationalerts.com", "stickybomb.kr", "donate.stream"];
	const vaildData = datas['link'].map((link, index) => {
		if (!link) {
			return defaultData;
		}

		// 둘 다 빈 값인 경우, 사용자가 임의로 지웠을 때 (삭제버튼 누른것과 동일)
		if ((!link.link_name || link.link_name === "") && (!link.url || link.url === "")) {
			return link;
		} else if (!link.url || link.url === "") {
			//링크 제목은 있는데, URL 값이 없는 경우
			toastr.error(i18next.t("URL을 입력해주세요."), {
				timeOut: 2000,
			});
			is_error = true;
		} else if (link.url.match(res) === null) {
				toastr.error(i18next.t("URL에 올바른 주소 형식을 입력해 주세요."), {
				timeOut: 2000,
			});
			is_error = true;
		} else if (link.link_name === "") {
			// URL 값은 있는데, 링크 제목이 없는 경우
			toastr.error(i18next.t("링크 제목을 입력해주세요."), {
				timeOut: 2000,
			});
			is_error = true;
		}else{		
			var matchedLists = link.url.match(new RegExp(banUrlList.join("|"), "g"));
			
			// 일치하는 금지 URL 체크
			if (matchedLists) {
				toastr.error(i18next.t("입력한 URL은 외부 링크로 등록할 수 없습니다."), {
					timeOut: 2000,
				});
				is_error = true;
			} 
		}
		return link;
	});

	if (!is_error) {
		try {
			await axios.post(`${ROOT_URL}/${global.bj_id}/setting/link`, vaildData);
			toastr.success(i18next.t("저장 되었습니다."), {
				timeOut: 2000,
			});
	
		} catch (error) {
			toastr.error(error.message, {
				timeOut: 2000,
			});
		}
	}

	return is_error;
}
