import { isIOS, isMobile } from "react-device-detect";

import fetchJsonp from "fetch-jsonp";
import querystring from "querystring";

import store from "store";
import { isWebview } from "helpers";

import { TITLE_CONTENTS } from "constant/search";
import {
	ACTCODE_TITLE,
	ACTTYPE_POST,
	LOCATION_STATION,
	M_SEARCH_ACTION_LOG,
	M_SEARCH_LOG,
	SCKETC_URL,
	ST_LATEST,
	STYPE_DIRECT,
	getSearchFilter,
} from "constant/log";

/**
 * PC Web: webk
 * Mobile Web: webm
 * Afreeca Webview
 *  - IPhone: ios1
 *  - Android: adr1
 *
 * @type {string}
 */
const os = isMobile ? (isWebview ? (isIOS ? "ios1" : "adr1") : "webm") : "webk";

/**
 * 검색 로그 수집
 * @param {string} keyword
 * @param {string} filter
 * @param actType
 * @param searchType
 */
export function searchLog({ keyword = "", filter = TITLE_CONTENTS, actType = ACTTYPE_POST, searchType = STYPE_DIRECT }) {
	const { user_id: userId } = store.getState().authenticationReducer;
	const parameter = {
		// 기본 값
		m: M_SEARCH_LOG,
		v: "1.0",
		// 기존 통합검색에서 encodeURIComponent 두번 사용중 (jquery ajax 호출 시 한번, ajax 내부에서 추가 encode)
		d: encodeURIComponent(keyword),
		t: "json",
		c: "UTF-8",
		w: os,
		uid: userId,

		// 액션 정보
		acttype: actType,
		ut: "sv",
		stype: searchType,
		location: LOCATION_STATION,
		ft: getSearchFilter(filter),
	};

	fetchJsonp(SCKETC_URL + "/api.php?" + querystring.stringify(parameter));
}

/**
 * 검색 액션 로그 수집
 * @param acttype
 * @param {string} keyword
 * @param {string} filter
 * @param sort
 * @param {string} actcode
 * @param searchType
 * @param {object} etcInfo
 */
export function searchActionLog({
	acttype = ACTTYPE_POST,
	keyword = "",
	filter = TITLE_CONTENTS,
	sort = ST_LATEST,
	actcode = ACTCODE_TITLE,
	searchType = STYPE_DIRECT,
	etcInfo = {},
}) {
	const { user_id: userId } = store.getState().authenticationReducer;
	const parameter = {
		// 기본 값
		m: M_SEARCH_ACTION_LOG,
		v: "1.0",
		// 기존 통합검색에서 encodeURIComponent 두번 사용중 (jquery ajax 호출 시 한번, ajax 내부에서 추가 encode)
		d: encodeURIComponent(keyword),
		t: "json",
		c: "UTF-8",
		w: os,
		uid: userId,

		// 액션 정보
		acttype: acttype,
		actcode: actcode,
		st: sort,
		stype: searchType,
		location: LOCATION_STATION,
		ft: getSearchFilter(filter),

		// 추가 정보 세팅
		...etcInfo,
	};

	fetchJsonp(SCKETC_URL + "/api.php?" + querystring.stringify(parameter));
}
