import produce from "immer";
import {
    FETCH_SETTING_SHOPFREECA_LIST,
    FETCH_SETTING_SHOPFREECA_LIST_SUCCESS,
    FETCH_SETTING_SHOPFREECA_LIST_FAIL,
    UPDATE_SETTING_SHOPFREECA_LIST,
    DELETE_SETTING_SHOPFREECA,
    DELETE_ALL_SETTING_SHOPFREECA,
    FETCH_SETTING_ADD_SHOPFREECA_SUCCESS,
    FETCH_SETTING_SHOPFREECA_RELOAD,
    FETCH_SETTING_ADD_SHOPFREECA_FAIL, UPDATE_SETTING_SHOPFREECA_SORT,
    SELETE_SETTING_CHECK_SHOPFREECA_SUCCESS,
    FETCH_SETTING_MEMBER_CHECK_SUCCESS,
    FETCH_SETTING_MEMBER_CHECK_FAIL,
} from "actions/setting/setting.shopfreeca.action"
import {SUCCESS} from "constant/status";


const INITIAL_STATE = {
    init: true,
    error : null,
    add_marpple_list_error : null,
    add_curation_list_error : null,
    loading: true,
    isSave: false, // 상품 정렬 후 저장
    name: "",
    isMarppleSteamer: false,
    message: "",
    data: {
        broadcast_live: {}, // 라이브 커머스 정보 (1개)
        bj_config_product: [],  // BJ가 선택한 큐레이션 번호
        checked_all_product : [],  // BJ가 선택한 큐레이션 상품 정보
        checkedTempProduct : [],  // 저장 전 BJ가 선택한 큐레이션 번호
        all_curation_product : [],  //전체 큐레이션 상품 정보
        all_marpple_product : [],  //전체 마플 상품 정보
        proceeds_account: true, // proceeds_account 샵프리카 환전 정보 조회 프로세스 제거로 default false => true 로 변경 / 히스토리용으로 UI 유지
    }
};


const settingShopfreecaReducer = produce((state, action) => {
    switch (action.type) {
        // 방송국 설정 > 샵프리카 리스트 노출
        case FETCH_SETTING_SHOPFREECA_LIST:
            state.error = null;
            state.loading = true;
            state.init = false;
            break;

        case FETCH_SETTING_SHOPFREECA_LIST_SUCCESS:
            state.error = null;
            state.loading = false;
            state.init = false;

            state.data.broadcast_live = action.payload.broadcast_live;
            state.data.checked_all_product = action.payload.checked_all_product;
            state.data.bj_config_product = action.payload.bj_config_product;
            state.data.checkedTempProduct = action.payload.bj_config_product;
            state.data.proceeds_account = action.payload.proceeds_account;

            break;

        case FETCH_SETTING_SHOPFREECA_LIST_FAIL:
            state.error = action.payload;
            state.loading = false;
            state.init = false;
            break;
        case FETCH_SETTING_ADD_SHOPFREECA_FAIL:
            state.add_marpple_list_error = action.payload;
            state.add_curation_list_error = action.payload;
            state.loading = false;
            state.init = false;
            break;

        // 샵프리카 설정 페이지 상품 정렬 후 저장 업데이트
        case UPDATE_SETTING_SHOPFREECA_LIST:
            state.status = SUCCESS;
            state.isSave = true;
            state.init = false;
            state.data.checked_all_product = action.payload.all_product;
            state.data.bj_config_product = action.payload.sortItems;
            return;

        // 샵프리카 sort 후 저장 버튼 누를 때
        case UPDATE_SETTING_SHOPFREECA_SORT:
            state.isSave = false;
            return;

        // 샵프리카 설정 페이지 삭제
        case DELETE_SETTING_SHOPFREECA:
            state.loading = false;
            state.init = false;
            state.data.checked_all_product = state.data.checked_all_product.filter((product, idx) => {
                return  product.product_no !== action.payload
            });
            state.data.bj_config_product = state.data.bj_config_product.filter((data) => data !== action.payload);

            // 순서 정렬 후, 전체 상품 삭제 시 '순서 저장' 비활성화
            if (state.data.checked_all_product.length === 0 && state.data.bj_config_product.length === 0) {
                state.isSave = false;
            }
            return;

        // 샵프리카 설정 > 상품 전체 선택 취소 (전체 삭제)
        case DELETE_ALL_SETTING_SHOPFREECA:
            state.loading = false;
            state.init = false;
            state.data.checked_all_product = [];
            state.data.bj_config_product = [];
            return;

        // 샵프리카 설정 > 상품 추가 페이지 로드
        case FETCH_SETTING_ADD_SHOPFREECA_SUCCESS:
            state.loading = false;
            state.init = false;
            state.data.all_curation_product =  action.payload.all_curation_product;
            state.data.all_marpple_product =  action.payload.all_marpple_product;
            state.data.orderBy = action.payload.orderBy;
            //state.data.bj_config_product = action.payload.bj_config_product;
            return;

        // 샵프리카 설정 > 상품 선택 
        case SELETE_SETTING_CHECK_SHOPFREECA_SUCCESS:
            state.loading = false;
            state.init = false;
            state.data.checkedTempProduct =  action.payload.checkedTempProduct;
            return;
        
        // 샵프리카 설정 > 상품 추가 업데이트
        case FETCH_SETTING_SHOPFREECA_RELOAD:
            // 순서 내 선택한게 있는지 (교집합)
            const interConfigArr = state.data.bj_config_product.filter((item) => {
                return action.payload.checkedProductArr.includes(item);
            });

            const interCurationArr =  state.data.all_curation_product.filter((item) => {
                return action.payload.checkedProductArr.includes(item.product_no);
            });

            const interMarppleArr =  state.data.all_marpple_product.filter((item) => {
                return action.payload.checkedProductArr.includes(item.product_no);
            });

            state.data.bj_config_product = action.payload.checkedProductArr;
            state.data.checked_all_product = [...interMarppleArr, ...interCurationArr];
            return;

        // 마플 가능 스트리머 권한 체크 성공
        case FETCH_SETTING_MEMBER_CHECK_SUCCESS:
            state.isMarppleSteamer = action.payload.result;
            state.message = action.payload.message;
            return;
        // 마플 가능 스트리머 권한 체크 실패
        case FETCH_SETTING_MEMBER_CHECK_FAIL:
            state.result = action.payload.error;
            return;
            
        default:
            return;
    }
}, INITIAL_STATE);

export default settingShopfreecaReducer;

