import querystring from "querystring";
import cookie from "react-cookies";
import i18next from "i18next";
import { toastr } from "components/toastr";
import { ROOT_URL, FORM_CONTENT_TYPE } from "actions/config";
import { handleFetchErrors, handleFailedToFetch } from "helpers";
import { DELETE, POST, PUT } from "constant/method";

export const FETCH_BANNER = "FETCH_BANNER";
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";
export const FETCH_BANNER_FAILURE = "FETCH_BANNER_FAILURE";

export const STORE_BANNER_SUCCESS = "STORE_BANNER_SUCCESS";
export const STORE_BANNER_FAILURE = "STORE_BANNER_FAILURE";

export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_FAILURE = "UPDATE_BANNER_FAILURE";

export const UPLOAD_BANNER_SUCCESS = "CREAT_BANNER_SUCCESS";
export const UPLOAD_BANNER_FAILURE = "CREAT_BANNER_FAILURE";

export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAILURE = "DELETE_BANNER_FAILURE";

export function fetchBanner() {
	return (dispatch) => {
		dispatch({ type: FETCH_BANNER });
		fetch(`${ROOT_URL}/${global.bj_id}/setting/banner`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_BANNER_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_BANNER_FAILURE,
					payload: error ? error : { code: 0, message: error.message },
				});
			});
	};
}

export function storeBanner(props) {
	const data = new FormData();
	if (props.file && props.file[0] && props.file[0] !== "") {
		data.append("file", props.file[0]);
	}
	data.append("_token", cookie.load("XSRF-TOKEN"));
	data.append("banner_type", props.banner_type);
	data.append("file_name", props.file_name);
	data.append("view_yn", props.view_yn);
	data.append("link_url", props.link_url);
	return fetch(`${ROOT_URL}/${global.bj_id}/setting/banner`, {
		method: POST,
		body: data,
		credentials: "include",
	});
}

export function updateBanner(value, banner_idx) {
	const data = new FormData();
	if (value.file && value.file[0] && value.file[0] !== "") {
		data.append("file", value.file[0]);
	} else {
		data.append("file", '');
	}
	data.append("_token", cookie.load("XSRF-TOKEN"));
	data.append("_method", PUT);
	data.append("banner_idx", banner_idx);
	data.append("banner_type", value.banner_type);
	data.append("file_name", value.file_name);
	data.append("view_yn", value.view_yn);
	data.append("link_url", value.link_url);
	return fetch(`${ROOT_URL}/${global.bj_id}/setting/banner/${banner_idx}`, {
		method: POST,
		body: data,
		credentials: "include",
	});
}


export function UploadBanner(file, banner) {
	return (dispatch) => {
		const data = new FormData();
		data.append("banner_type", banner.banner_type);
		data.append("file", file);
		data.append("_token", cookie.load("XSRF-TOKEN"));
		fetch(`${ROOT_URL}/${global.bj_id}/setting/banner/image`, {
			method: POST,
			body: data,
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: UPLOAD_BANNER_SUCCESS,
					payload: {
						...response,
					},
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: UPLOAD_BANNER_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function deleteBanner(banner_idx) {
	return (dispatch) => {
		fetch(`${ROOT_URL}/${global.bj_id}/setting/banner/${banner_idx}`, {
			method: DELETE,
			headers: FORM_CONTENT_TYPE,
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), banner_idx: banner_idx }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				toastr.success(i18next.t("삭제 되었습니다."), {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_BANNER_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_BANNER_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}