import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProfileImage2 } from "components/ImageHandle";
import { LNB_PROFILE } from "constant/position.path";
import { Badge } from "components/contents/Badge";


const Profile = (props) => {
	const { t } = useTranslation();
	const { station = {}, is_partner_bj, is_best_bj, profile_image, id, path, position = LNB_PROFILE } = props;
	const { user_nick = "", user_id = "", groups } = station;
	const is_setting = path.indexOf("/:id/setting") > -1 ? true : false;
	return (
		<section className="bj_box">
			<Link to={`/${global.bj_id}`}>
				<ProfileImage2 className="thum" userId={global.bj_id} src={profile_image} position={position}/>
			</Link>
			<div className="info_box">
				<div className="nick">
					<h2>{user_nick}</h2>
					{!is_setting && (
						<span className="id">({user_id})</span>
					)}
					{is_setting && (
						<Link to={`/${id}`}>
							<button type="button" className="bs" tip={t("방송국")}>
								<span>
									<Trans>방송국</Trans>
								</span>
							</button>
						</Link>
					)}
				</div>
				{is_setting && (
					<span className="id">@{user_id}</span>
				)}
				{(is_best_bj || is_partner_bj || groups.length > 0) && !is_setting && (
					<Badge groups={groups} user_nick={user_nick}/>
				)}
			</div>
		</section>
	);
}

export default Profile;