export const AFREECATV_DOMAIN = "afreecatv.com";
export const IMG_DOMAIN = "//stimg.afreecatv.com";
export const PROFILE_IMG_DOMAIN = "//profile.img.afreecatv.com";
export const OLD_STATION_DOMAIN = "//st.afreecatv.com";
export const OLD_LIVE_DOMAIN = "//live.afreecatv.com";

export const LOGIN_URL = "https://login.afreecatv.com";
export const LOGOUT_URL = "https://login.afreecatv.com/app/LogOut.php";

export const AFREECA_MAIN_URL = "//www.afreecatv.com";
export const RES_AFREECATV = "//res.afreecatv.com";
// VOD_SHARE_URL v.afree.ca/ST 제거
export const VOD_PLAYER_URL = "//vod.afreecatv.com/player";
export const VOD_PLAYER_SSL_URL = "https://vod.afreecatv.com/player";
export const VOD_EDITOR_URL = "//veditor.afreecatv.com";
export const NOTE_URL = "//note.afreecatv.com/app/index.php?page=write";
export const NOTE_MOBILE_URL = "http://m.afreecatv.com/#/note/write2";
export const MY_REVENUE_MOBILE_URL = "http://m.afreecatv.com/myrevenue/a/myrevenue/";
export const EVENT_URL_MOBILE = "https://m.afreecatv.com/report/event";
export const TITLE_BLIND_MOBILE_URL = "https://m.afreecatv.com/station/titleblind/a/list";

export const STATION_URL = "//bj.afreecatv.com";
export const STATION_SSL_URL = "https://bj.afreecatv.com";
export const AFREECATV_URL = "//www.afreecatv.com";

export const PROFILE_DEFUALT_IMG_URL = "//res-cf.afreecatv.com/mybs/img/default_small_re.gif";

export const ITEM_MOBILE_URL = "https://item.m.afreecatv.com";

//PC URL
export const FAVORITE_URL = "//my.afreecatv.com/favorite";
export const USER_INFO_URL = "https://member.afreecatv.com/app/user_info.php";
export const RECEIVE_STARBALLOON_URL = "https://point.afreecatv.com/Balloon/AfreecaNormalExchange.asp";
export const SUBSCRIPTION_URL = "https://point.afreecatv.com/Subscription/SubscriptionList.asp";
export const STICKER_URL = "https://item.afreecatv.com/sticker.php";
export const BROARD_STATISCS = "//broadstatistic.afreecatv.com";
export const BEST_BJ_URL = "//bestbj.afreecatv.com";
export const PARTNER_BJ_URL = "//partnerbj.afreecatv.com";
export const AFSUPPORTERS_BJ_URL = "//supporters.afreecatv.com/";
export const MY_REVENUE_URL = "https://myrevenue.afreecatv.com/";
export const ADBALLOON_URL = "//adballoon.afreecatv.com";
export const ESPORTS_URL = "//esports.afreecatv.com";
export const EVENT_URL = "//afevent.afreecatv.com";
export const DASHBOARD = "//dashboard.afreecatv.com";
export const POINT_AFREECATV = "https://point.afreecatv.com"

export const LIVEIMG_URL = "//liveimg.afreecatv.com";
export const SEARCH_API_URL = "//scketc.afreecatv.com/api.php";
export const JAVASCRIPT_VOID = "javascript:void(0)";

export const VOTE_URL = "//vote.afreecatv.com";
export const VOTE_PROTOCAL_URL = (() => {
    const url = new URL(window.location.href);
    return url.protocol + VOTE_URL;
})();

export const STBBS_URL = "//stbbs.afreecatv.com";

export const BBS_IMAGE_VIEW_URL = "//bj.afreecatv.com/image/detail_image.html";

export const SCH_URL = "//sch.afreecatv.com"