import produce from "immer";

import { FETCH_BROADCAST_LIVE, FETCH_BROADCAST_LIVE_SUCCESS, FETCH_BROADCAST_LIVE_FAILURE } from "actions/shopfreeca.live.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
    status: CLEAR,
    error: null,
    data: []
};


const shopfreecaLiveReducer = produce((state, { type, payload }) => {
    switch (type) {
        // 샵프리카 라이브 커머스 로딩 시작
        case FETCH_BROADCAST_LIVE:
            state.status = LOADING;
            return;

        // 샵프리카 라이브 커머스 조회 성공
        case FETCH_BROADCAST_LIVE_SUCCESS:
            state.status = SUCCESS;
            state.data = payload.data;
            return;

        // 샵프리카 라이브 커머스 조회 실패
        case FETCH_BROADCAST_LIVE_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        default:
    }
}, INITIAL_STATE);

export default shopfreecaLiveReducer;
