import React, { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { JAVASCRIPT_VOID } from "constant/config";
import { numberCommaString, goGiftAdBalloon, handleCeremonyImgError } from "helpers";
import classNames from "classnames";
import { ERROR_IMG, ACHIVEMENT_MODAL_IMG_PC, ACHIVEMENT_MODAL_IMG_MOBILE, EVENT_INFO, EventImgPC, EventImgMobile } from "constant/adballoon.event";
import { isMobile } from "react-device-detect";

export default function AdballoonEventLayer({onClose, onOpenModal, gift}) {
    const { status = "success", data = [] } = useSelector(
		({ giftEventReducer }) => ({
			status: giftEventReducer.status,
			data: giftEventReducer.data,
		}),
		shallowEqual,
    );

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const goAdballoonModal = useCallback(() => {
        onClose();
        const openId = goGiftAdBalloon(global.bj_id);
        onOpenModal(openId, "ADBALLOON");
    }, [onOpenModal, onClose]);

    const goEventInfo = useCallback(() => {
        let url = EVENT_INFO[global.bj_id].info_url
        window.open(url);  
    }, []);

    //젬 url 이동 : 추후에 쓰일 수 있어서 주석처리
    // const goGemQuest = useCallback(() => {
    //     window.open(GEM_QUEST_URL);
    // }, []);

    const errorImg = ERROR_IMG;

    const eventModalImg = useMemo(() => {
        const bj_id = global.bj_id;
        
        if(data.achievement_date === undefined) {
            return null;
        }

        if(data.achievement_date){
            return isMobile ? ACHIVEMENT_MODAL_IMG_MOBILE : ACHIVEMENT_MODAL_IMG_PC;
        }
        return isMobile ? EventImgMobile(bj_id) : EventImgPC(bj_id);
     }, [data.achievement_date]); 

    const eventItem = useMemo(() => {
            return (
                <>
                    <div className="pop-title">
                        <h3>{data.bj_nick} 방송국의 {isMobile && (<br />)}애드벌룬 선물 현황</h3>
                    </div>
                    <div className="pop-body">
                        <div className="adballon_progress_box">
                            <div className={classNames("progress ", {win: data.achievement_date })}>
                                <div className="msg">
                                    <span><strong>{numberCommaString(data.total_balloon_count)}</strong>개 ({data.achievement_percent}% 달성)</span>
                                </div>
                                <span className="gauge" style={{width:`${data.achievement_percent}%`}}></span>
                            </div>
                            <div className="info">
                                <span>내가 선물한 애드벌룬 : <strong>{numberCommaString(data.send_balloon_count === '-' ? 0 : data.send_balloon_count)}</strong>개</span>
                                <span>목표 : {numberCommaString(data.achievement_count)}개</span>
                            </div>
                        </div>
                        
                        {/* 선물 했을 때 */}
                        {gift && (
                            <>
                            <div className="ceremony">
                                <img src={gift.gift_img} alt=""onError={(e) => handleCeremonyImgError(e, "ADBALLOON", gift.gift_cnt, errorImg)} />
                                <div className="msg">
                                    <p><strong>{data.bj_nick}</strong>님의 방송국에<br />애드벌룬<em>{numberCommaString(gift.gift_cnt)}</em>개 선물!</p>
                                    {gift.fanclub_order > 0 && <span>{numberCommaString(gift.fanclub_order)}번째로 <strong>팬클럽</strong>이 되신걸 축하드립니다!</span>}
                                </div>
                            </div>
                            <p className="msg_event">애드벌룬 선물 이벤트에 응모 되었습니다!</p>
                            </>
                        )}
    
                        {/* 목표달성 안됬을 경우  */}
                        {!gift && (
                            <div className="eventImg">
                                <img src={eventModalImg} />
                                {data.achievement_date && <span className="date">달성일 : {data.achievement_date}</span>}
                            </div>
                        )}                    
                    </div>
                    <div className="pop-btn">
                        {/*gift여부의따라 아래 버튼은 달리한다*/}
                        <a href={JAVASCRIPT_VOID} className="btn-basic" onClick={goEventInfo}>이벤트 보러가기</a>
                        <button type="button" className="btn-basic blue" onClick={goAdballoonModal}>애드벌룬 선물하기</button>
                        {/* {gift && <a href={JAVASCRIPT_VOID} className="btn-basic" onClick={goGemQuest} >젬 받기</a>} */}
                    </div>                   
                    <button type="button" className="pop-close" onClick={handleClose}>닫기</button>
            </>
    
            );

    }, [data, eventModalImg, goAdballoonModal, handleClose])
    
    return (
        data && (
            <div className={`ballon_event_layer ${!isMobile ? 'ui-pop' : ''}`}>
                {isMobile && (
                    <div className="pop-cont">
                        {eventItem}
                    </div>
                )}

                {!isMobile && (eventItem)}
            </div>
        )

    );
}