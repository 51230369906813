import axios from "axios";
import i18next from "i18next";
import cookie from "react-cookies";
import { makeStationImageHtml } from "../helpers";
import { editorInsertHtml } from "./ckeditor.action";
import { ROOT_URL } from "./config";
import { toastr } from "components/toastr";

export const PHOTO_OPEN_DIALOG = "PHOTO_OPEN_DIALOG";
export const PHOTO_FORM_RESET = "PHOTO_FORM_RESET";

export const PHOTO_UPLOAD_START = "PHOTO_UPLOAD_START";
export const PHOTO_UPLOAD_INIT = "PHOTO_UPLOAD_INIT";
export const PHOTO_UPLOAD_PROGRESS = "PHOTO_UPLOAD_PROGRESS";
export const PHOTO_UPLOAD_END = "PHOTO_UPLOAD_END";
export const PHOTO_UPLOAD_COMPLETE = "PHOTO_UPLOAD_COMPLETE";
export const PHOTO_UPLOAD_FAILURE = "PHOTO_UPLOAD_FAILURE";
export const PHOTO_ADD_LIST = "PHOTO_ADD_LIST";
export const PHOTO_ADD = "PHOTO_ADD";
export const PHOTO_SET_TITLE = "PHOTO_SET_TITLE";
export const PHOTO_SET_DEFAULT_TITLE = "PHOTO_SET_DEFAULT_TITLE";
export const PHOTO_DELETE = "PHOTO_DELETE";
export const PHOTO_REMOVE_ALL = "PHOTO_REMOVE_ALL";

let fileQueue = [];
let imageUploadCaller = null;
function imageUpload(dispatch, props, form) {
    return (index) => {
        if (fileQueue.length) {
            const file = fileQueue.shift();
            const data = new FormData();
            data.append("file", file);
            data.append("_token", cookie.load("XSRF-TOKEN"));
            var uploadKey = `${file.name}_${Math.random()}`;

            dispatch({
                type: PHOTO_UPLOAD_INIT,
                payload: {
                    uploadKey,
                },
            });

            axios({
                method: "post",
                data,
                url: `${ROOT_URL}/${global.bj_id}/title/image`,
                withCredentials: true,
                onUploadProgress(progressEvent) {
                    /**
                     * 로딩진행률
                     */
                    const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    dispatch({
                        type: PHOTO_UPLOAD_PROGRESS,
                        payload: {
                            uploadKey,
                            progress,
                        },
                    });
                },
            }).then(
                (response) => {
                    const data = response.data;
                    dispatch({
                        type: PHOTO_FORM_RESET,
                    });
                    dispatch({
                        type: PHOTO_UPLOAD_COMPLETE,
                        payload: {
                            uploadKey,
                            progress: 100,
                            rnum: 2,
                            ...data,
                        },
                    });
                    dispatch(editorInsertHtml(makeStationImageHtml(data.url)));
                    imageUploadCaller();
                },
                (error) => {
                    toastr.error(error.response ? i18next.t(error.response.data.message) : i18next.t(error.message), {
                        timeOut: 2000,
                    });
                    dispatch({
                        type: PHOTO_UPLOAD_FAILURE,
                        payload: {
                            uploadKey,
                        },
                    });
                    imageUploadCaller();
                },
            );
        } else {
            dispatch({ type: PHOTO_UPLOAD_END });
        }
    };
}

/**
 * 이미지 멀티 업로드(순차 업로드)
 * @param {*} files
 * @param {*} editor
 * @param {*} form
 */
let index = 0;
export function multiUpload(props, files, form) {
    return (dispatch) => {
        dispatch({ type: PHOTO_UPLOAD_START });

        for (var i = 0; i < files.length; i++) {
            if (files[i].size > 20 * 1024 * 1024) {
                alert(i18next.t("20M가 초과하는 이미지는 업로드 하실수 없습니다."));
                continue;
            }
            fileQueue.push(files[i]);
        }

        const { postsPhotoReducer = [] } = props;
        index = postsPhotoReducer.length;

        if (files.length + postsPhotoReducer.length > 30) {
            fileQueue = [];
            dispatch({
                type: PHOTO_FORM_RESET,
            });
            alert(i18next.t("이미지 파일이 30개가 넘어 이미지를 올리실 수 없습니다."));
        } else {
            imageUploadCaller = imageUpload(dispatch, props, form);
            imageUploadCaller(index);
        }
    };
}

/**
 * 이미지 선택 다이얼로그
 * @param {*} uploadKey
 */
export function openImageDialog(uploadKey) {
    return (dispatch) => {
        dispatch({
            type: PHOTO_OPEN_DIALOG,
        });
    };
}

/**
 * 이미지 삭제
 * @param {*} photo
 */
export function addPhoto(photo) {
    return (dispatch) => {
        dispatch({
            type: PHOTO_ADD,
            payload: photo,
        });
    };
}

/**
 * 이미지 삭제
 * @param {*} key
 */
export function deleteImage(key) {
    return (dispatch) => {
        dispatch({
            type: PHOTO_DELETE,
            key,
        });
    };
}

/**
 * 대표이미지 선택
 * @param {*} key
 */
export function setTitleImage(key) {
    return (dispatch) => {
        dispatch({
            type: PHOTO_SET_TITLE,
            key,
        });
    };
}

/**
 * 기본 대표이미지 선택
 */
export function setDefaultTitle() {
    return (dispatch) => {
        dispatch({
            type: PHOTO_SET_DEFAULT_TITLE,
        });
    };
}
