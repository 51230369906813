import { INSTAGRAM, YOUTUBE } from "constant/sns";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useSns() {
    const stationInfo = useSelector((state) => state.stationReducer.stationInfo);
    const bjInfo = useSelector((state) => state.stationReducer.bjInfo);
    const { user_id } = useSelector((state) => state.authenticationReducer);



    const [instagram, youtube] = useMemo(() => {
        const { data = {} } = stationInfo;
        const { station = {} } = data;
        const { sns = [] } = station;

        return [
            sns.find((sns) => sns.type === INSTAGRAM),
            sns.find((sns) => sns.type === YOUTUBE)
        ];
    }, [stationInfo]);

    const isYoutubeAd = useMemo(() => {
        const { links = [] } = bjInfo;
        const youtubeLink = links.findIndex((link) => link.type === 3) > -1;
        return (
            global.bj_id === user_id && //본인
            youtubeLink && //유튜브 링크가 잇고
            !youtube //유튜브SNS를 연결하지 안했을 경우
        );
    }, [bjInfo, user_id, youtube]);

    const isInstagramAd = useMemo(() => {
        const { links = [] } = bjInfo;
        const instagramLink = links.findIndex((link) => link.type === 2) > -1;
        return (
            global.bj_id === user_id && //본인
            instagramLink && //인스타링크가 잇고
            !instagram //인스타SNS를 연결하지 안했을 경우
        );
    }, [bjInfo, user_id, instagram]);

    return { instagram, youtube, isInstagramAd, isYoutubeAd };
}
