import axios from "libs/axios";
export const FETCH_BROADCAST_LIVE = 'FETCH_BROADCAST_LIVE';
export const FETCH_BROADCAST_LIVE_SUCCESS = 'FETCH_BROADCAST_LIVE_SUCCESS';
export const FETCH_BROADCAST_LIVE_FAILURE = 'FETCH_BROADCAST_LIVE_FAILURE';


export const fetchShopfreecaLiveList = () => async (dispatch) => {
    // 로딩 시작
    dispatch({ type: FETCH_BROADCAST_LIVE });

    // 샵프리카 리스트 조회 url
    let url = `/${global.bj_id}/shopfreeca/live`;

    try {
        // api 통신 시작
        const response = await axios.get(url);

        dispatch({
            type: FETCH_BROADCAST_LIVE_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_BROADCAST_LIVE_FAILURE,
            payload: error,
        });
    }
};

// order by 할 경우 curtation data만 append 쳐야할지
// 큐레이션 api 가 따로 분리가 안되어있어서 이건 나중에..