import { destroySns, fetchInstagramFollowedByEmbed, fetchInstagramInvalidated, storeSns } from "actions/sns.action";
import classNames from "classnames";
import { toastr } from "components/toastr";
import { INSTAGRAM_API, INSTAGRAM_CLIENT_ID, INSTAGRAM_REDIRECT_URL } from "constant/sns";
import React, { useCallback, useEffect, useState } from "react";
import { isIE, isMobile, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function SnsInstagram({ sns: propsSns }) {
    const { t } = useTranslation();
    const [sns, setSns] = useState(propsSns);
    const [isPrivate, setPrivate] = useState(null);
    const [invalidatedToken, setInvalidatedToken] = useState(false);
    useEffect(() => {
        setSns(propsSns);
    }, [propsSns]);

    /**
     * 인스타그램 OAuth2 Callback
     */
    const handleMessageReceivedFromCallback = useCallback((event) => {
        if (event.origin !== "https://bj.afreecatv.com") return;
        const { source, code } = event.data;
        if (source && source === "instagram_callback") {
            storeSns({ type: 2, code })
                .then((response) => {
                // message event 값으로 들어가는 케이스가 있음
                    if (response.result == 1) {
                        setSns(response);
                        toastr.success(response.message);
                    } else {
                        toastr.error(response.message);
                    }

            });
        }
    }, []);

    /**
     * PostMessage Handler 추가
     */
    useEffect(() => {
        window.addEventListener("message", handleMessageReceivedFromCallback);
        return () => window.removeEventListener("message", handleMessageReceivedFromCallback);
    }, [handleMessageReceivedFromCallback]);

    /**
     * 인스타그램 연결 (인증 창띄우기)
     */
    const handleConnect = useCallback(() => {
        const url = `${INSTAGRAM_API}/oauth/authorize?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${INSTAGRAM_REDIRECT_URL}&scope=user_profile,user_media&response_type=code&state=${window.location.protocol}`;
        if (isIE) {
            alert(
                "인터넷 익스플로러에서는 인스타그램 계정 연결 기능을 이용하실 수 없습니다.\n다른 브라우저(크롬, 파이어폭스,엣지 등)에서 연결해주세요.",
            );
            return;
        }

        if(isMobile && isIOS) {
            /**
             * iOS9 부터 지원하는 딥 링크에서 앱으로 이동되는 이슈가 있음
             * 1초 내외로 반응 없을 시 해당 url로 인앱 페이지 이동 처리 setTimeout 쓰지 않을 시 os내에 딥링크 작용으로 설치된 앱으로 이동이 됨  (setTimeOut으로 매치되는 url을 open 되는것을 막음)
             * but, 페이지 이동 후 그 페이지에서 다른 페이지 이동 시는 처리가 불가
             */
            setTimeout(() => {
                window.open(
                    url,
                    "afreecatv_instagram_authorize",
                    "width=500,height=700",
                );
            }, 1000);
            return;
        }
        window.open(
            url,
            "afreecatv_instagram_authorize",
            "width=500,height=700",
        );
    }, []);

    /**
     * 인스타그램 해제
     */
    const handleDisconnect = useCallback(
        (id) => () => {
            if (window.confirm(t("연결된 계정을 해제하시겠습니까? 연결을 해제하면 방송국 홈에 해당 SNS 컨텐츠가 노출되지 않습니다."))) {
                destroySns(id).then((response) => {
                    setSns(null);
                    toastr.success(response.message);
                });
            }
        },
        [t],
    );

    /**
     * 계정이 비공개 처리되어 있는경우 체크
     * -- 팔로우수 api를 통해 공개여부를 체크함
     * -- 팔로우수 api(첫번째 콘텐츠의 embed 내용을 가져오므로 오류나면 비공개로 인지)
     */
    useEffect(() => {
        if (sns && sns.title) {
            fetchInstagramFollowedByEmbed(sns.id, false)
                .then((response) => {
                    setPrivate(false);
                })
                .catch((e) => {
                    if (e.code === 190) {
                        setInvalidatedToken(true);
                    } else {
                        setPrivate(true);
                    }
                });
        }
    }, [sns]);

    /**
     * 토큰이 정상인지 체크 (비공개가 아닐때만)
     *  - https://graph.instagram.com/me/media api를 호출해서 토큰의 정상여부 판단
     *  - media일 경우에도 토큰 정상여부 판단이 정상적으로 오지 않을때가 있음
     */
    useEffect(() => {
        console.log("isPrivate", isPrivate);
        if (isPrivate === false && sns && sns.id) {
            fetchInstagramInvalidated(sns.id)
                .then((response) => {
                    setInvalidatedToken(false);
                })
                .catch((e) => {
                    setInvalidatedToken(true);
                });
        }
    }, [isPrivate, sns]);

    return (
        <div className="sns_box">
            <div className="lt">
                <h4 className="ico-instar">
                    {t("인스타그램")}
                </h4>
                {isMobile && sns && (
                    <span className="channel">
                        {t("채널명")} <em>{sns.title}</em>
                    </span>
                )}
                {!isMobile && <span className="desc">{t("아프리카TV 방송국에 프로필, 이미지, 동영상 공유")}</span>}
                {!isMobile && sns && (
                    <dl>
                        <dt>{t("채널명")}</dt>
                        <dd>
                            <strong>{sns.title}</strong>
                        </dd>
                    </dl>
                )}
            </div>
            <div className="rt">
                {sns ? (
                    <button type="button" className={classNames(isMobile ? "btn" : "btn-basic")} onClick={handleDisconnect(sns.id)}>
                        <span>{isMobile ? t("해제") : t("연결 해제")}</span>
                    </button>
                ) : (
                    <button type="button" className={classNames(isMobile ? "btn" : "btn-basic blue")} onClick={handleConnect}>
                        <span>{t("연결")}</span>
                    </button>
                )}
            </div>
            {sns && isPrivate && <p className="text_reconnect">{t("계정이 비공개 상태입니다. 공개 계정으로 연결해주세요.")}</p>}
            {sns && invalidatedToken && (
                <p className="text_reconnect">{t("계정 상태가 변경되었습니다. 연결 해제 후 계정을 다시 연결해주세요.")}</p>
            )}
        </div>
    );
}
