import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";

export const SubHeaderMobile = (props) => {
	const history = useHistory();

	const handleGoBack = () => {
		if (props.onBack) {
			props.onBack();
			return;
		}
		history.goBack();
	};

	const { backLink } = props;

	return (
		<header>
			<section className="basic-section">
				<div className="grid-start">
					{backLink ? (
						<Link to={backLink}>
							<button type="button" className="btn-back">
								<span>
									<Trans>뒤로가기</Trans>
								</span>
							</button>
						</Link>
					) : (
						<button type="button" className="btn-back" onClick={() => handleGoBack()}>
							<span>
								<Trans>뒤로가기</Trans>
							</span>
						</button>
					)}
				</div>
				<h2 className="title">
					<p>{props.title}</p>
				</h2>
				<div className="group-end">{props.children}</div>
			</section>
		</header>
	);
};
