import Footer from "components/footer/Footer";
import { AFREECATV_URL, STATION_URL } from "constant/config";
import { isWebview } from "helpers";
import React from "react";
import { Trans } from "react-i18next";

export const StationError = (props) => {
	const { error } = props;
	const afreecaLink = isWebview ? "afreeca://browser/close" : AFREECATV_URL;
	switch (error.code) {
		case 9000: //방송국없음
		case 9001: //블라인드
			return [
				<div key={0} id="bs-container">
					<div id="bs-contents" className="bs_status">
						<article>
							<h2>
								<Trans>방송국이 존재하지 않습니다.</Trans>
							</h2>
							<p>
								<Trans>개설되지 않은 방송국이거나 주소가 잘못되었을 수 있습니다.</Trans>
							</p>
							<a href={afreecaLink} className="btn-basic blue">
								<span>
									<Trans>아프리카TV 메인으로 가기</Trans>
								</span>
							</a>
						</article>
					</div>
				</div>,
				<Footer key={1} className="full" />,
			];
		case 9002: //휴면
			return [
				<div key={0} id="bs-container">
					<div id="bs-contents" className="bs_status">
						<article>
							<h2>
								<Trans>해당 이용자는 휴면 상태입니다.</Trans>
							</h2>
							<p>
								<Trans>이용자 본인 요청으로 방송국이 휴면 상태입니다.</Trans>
								<br />
								<Trans>휴면 해제를 원하시면 고객센터로 문의해 주시기 바랍니다.</Trans>
							</p>
							<a href={afreecaLink} className="btn-basic blue">
								<span>
									<Trans>아프리카TV 메인으로 가기</Trans>
								</span>
							</a>
						</article>
					</div>
				</div>,
				<Footer key={1} className="full" />,
			];
		case 9003: // 에러
			console.log("bj_id", global.bj_id);
			return [
				<div key={0} id="bs-container">
					<div id="bs-contents" className="bs_status">
						<article>
							<h2>
								<Trans>요청하신 페이지를 표시할 수 없습니다.</Trans>
							</h2>
							<p>
								<Trans>페이지 주소가 잘못되었거나, 잘못된 요청으로 인해 페이지를 표시할 수 없습니다.</Trans>
								<br />
								<Trans>방송국 메인 페이지로 이동하신 후, 다시 시도해주세요.</Trans>
							</p>
							<a href={`${STATION_URL}/${global.bj_id}`} className="btn-basic blue">
								<span>방송국 메인으로 가기</span>
							</a>
						</article>
					</div>
				</div>,
			];
		case 9100: //휴면
			return [
				<div key={0} id="bs-container">
					<div id="bs-contents" className="bs_status">
						<article className="stop">
							<h2>
								<Trans>서비스 이용이 정지된 방송국입니다.</Trans>
							</h2>
							<p>
								<Trans>서비스 이용정지 기간 중에는 방송국을 이용하실 수 없습니다.</Trans>
							</p>
							<div className="stop_reason">
								<ul>
									<li>
										<strong>
											<Trans>정지일</Trans>&nbsp;
										</strong>
										{error.black.regist_date}
									</li>
									<li>
										<strong>
											<Trans>해제일</Trans>&nbsp;
										</strong>
										{error.black.expire_date}
									</li>
									<li>
										<strong>
											<Trans>정지 기간</Trans>&nbsp;
										</strong>
										{error.black.regist_term}
									</li>
									<li>
										<strong>
											<Trans>정지 사유</Trans>&nbsp;
										</strong>
										{error.black.reason}
									</li>
								</ul>
							</div>
							<a href={afreecaLink} className="btn-basic blue">
								<span>
									<Trans>아프리카TV 메인으로 가기</Trans>
								</span>
							</a>
						</article>
					</div>
				</div>,
				<Footer key={1} className="full" />,
			];
		case 1300://방송국 블랙리스트
			return [
				<div key={0} id="bs-container">
					<div id="bs-contents" className="bs_status">
						<article className="stop">
							<h2>{error.message}</h2>
							<a href={afreecaLink} className="btn-basic blue">
								<span>
									<Trans>아프리카TV 메인으로 가기</Trans>
								</span>
							</a>
						</article>
					</div>
				</div>,
				<Footer key={1} className="full" />,
			];
		default:
			return [
				<div key={0} id="bs-container">
					<div id="bs-contents" className="bs_status">
						<article>
							<p>{error.message}</p>
							<a href={afreecaLink} className="btn-basic blue">
								<span>
									<Trans>아프리카TV 메인으로 가기</Trans>
								</span>
							</a>
						</article>
					</div>
				</div>,
				<Footer key={1} className="full" />,
			];
	}
};
