import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RES_AFREECATV } from "constant/config";
import { useDispatch } from "react-redux";
import { fetchGiftEvent } from "actions/gift.event.action";
import { EVENT_INFO } from "constant/adballoon.event";

export default function GiftEvent({onOpen, percent, onOpenModal, channelArt = false}) {

    const dispatch = useDispatch();
    const [tooltipShow, setTooltipShow] = useState(true);


    /**
     * 이벤트 데이터 가져오기
     */
    useEffect( () => {
        if(onOpen) {
            dispatch(fetchGiftEvent);
        }
    }, [onOpen, dispatch]);

    /**
     * 로티 애니메이션 추가
     */
    
    useEffect(() => {
        if(!channelArt) {
            /**
             * //res.afreecatv.com/script/lottie_svg.min.js 사용 
             */
            let svgContainer = document.querySelector('.imgbox');
            let animItem = window.bodymovin.loadAnimation({
                wrapper: svgContainer,
                animItem: 'svg',
                autoplay: true,
                loop: false,
                path: `${RES_AFREECATV}/images/lottie/adballoon_event_pc.json`
            });
    
            let animated = document.querySelector('.balloon_percent');
            let graph = document.querySelector('.balloon_percent .chart span');
            let graph_val = percent;
            animated.addEventListener('DOMLoaded', () => {
                animItem.playSegments([0, 100], true);
            });
    
            animItem.onComplete = () => {
                animated.classList.add('on');
            }

            animated.onanimationend = () => {
                /**
                 * api 쪽에서 % 붙여서 나와 추가적으로 % 붙일 필요는 없음
                 */
                graph.style.width = graph_val;
            }
        }
    }, []);    

    const handleOpenModal = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        onOpenModal();
    }, [onOpenModal]);

    const handleToolTipClose = useCallback((event) => {
        event.preventDefault();

        setTooltipShow(false);
    }, [setTooltipShow]);


    // const goWinnerBoard = useCallback((event) => {
    //     window.open("https://bj.afreecatv.com/afsupport/post/87832854");
    // }, []);
    
    // 활성화가 됬는지 먼저 확인했을경우
    // const tooltip = useMemo(() => {
    //     const winnerNickName = Object.keys(winnerList[0]);
    //     const winnerUserId = Object.values(winnerList[0]);

    //     return (
    //         <div className="tooltip" onClick={goWinnerBoard}>
    //             {/* 한개 일때 */}
    //             <p>
    //                 <strong>축 당첨!</strong>
    //                 <span>
    //                     <em>
    //                         {winnerNickName[0]}
    //                     </em>
    //                     ({winnerUserId[0]})
    //                     {winnerNickName.length > 1 && (",")}
    //                 </span>
    //             </p>
    //             {/* 두개 이상 일때 */}
    //             {winnerNickName.length > 1 && (
    //                 <div className="nameBox">
    //                     {winnerNickName.map((winner, index) => {
    //                         if(index > 0) {
    //                             return(
    //                             <span key={index}>
    //                                 <em>{winner}</em>
    //                                 ({winnerUserId[index]})
    //                                 { ((winnerNickName.length -1) !== index) && (",")}
    //                             </span>
    //                             ); 
    //                         }
    //                     })}
    //                 </div>
    //             )}
    //         </div>
    //     );
    // }, [handleToolTipClose]);

    const tooltip = useMemo(() => {
        return (
            <div className="tooltip">
                <div onClick={handleOpenModal}>
                    <p>방송국에 애드벌룬 선물하면</p>
                    <p>{global.bj_id in EVENT_INFO ? EVENT_INFO[global.bj_id].item : "BJ굿즈"}를 드려요!</p>
                </div>
                <button type="button" className="close" onClick={handleToolTipClose}>닫기</button>
            </div>
    )
    })

    if(!channelArt) {
        return(
            <div className="balloon_state ballonEvent" >
                <div className="imgbox">
                </div> 
    
                <div className="balloon_percent" onClick={handleOpenModal}>
                    <div className="chart">
                        <span></span>
                    </div>
                </div>
    
                {tooltipShow && (tooltip)}
            </div>
        );
    } else {
        return null;
    }
}