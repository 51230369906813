import querystring from "querystring";
import i18next from "i18next";
import { FORM_CONTENT_TYPE, ROOT_URL } from "./config";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { isMobile } from "react-device-detect";
import { DELETE, POST, PUT } from "constant/method";
import cookie from "react-cookies";
import { toastr } from "components/toastr";

export const FETCH_BLACKLIST = "FETCH_BLACKLIST";
export const FETCH_BLACKLIST_SUCCESS = "FETCH_BLACKLIST_SUCCESS";
export const FETCH_BLACKLIST_FAILURE = "FETCH_BLACKLIST_FAILURE";

export const STORE_BLACKLIST = "STORE_BLACKLIST";
export const STORE_BLACKLIST_SUCCESS = "STORE_BLACKLIST_SUCCESS";
export const STORE_BLACKLIST_FAILURE = "STORE_BLACKLIST_FAILURE";

export const DELETE_BLACKLIST = "DELETE_BLACKLIST";
export const DELETE_BLACKLIST_SUCCESS = "DELETE_BLACKLIST_SUCCESS";
export const DELETE_BLACKLIST_FAILURE = "DELETE_BLACKLIST_FAILURE";

export const UPDATE_BLACKLIST = "UPDATE_BLACKLIST";
export const UPDATE_BLACKLIST_SUCCESS = "UPDATE_BLACKLIST_SUCCESS";
export const UPDATE_BLACKLIST_FAILURE = "UPDATE_BLACKLIST_FAILURE";

export const SET_BLACKLIST_POST = "SET_BLACKLIST_POST";

export const SET_BLACKLIST_MEMO = "SET_BLACKLIST_MEMO";

/**
 * 블랙리스트 가져오기
 * @param page
 * @returns {Function}
 */
export function fetchBlacklist(page = 1, word = "", type = "black_id") {
    return (dispatch) => {
        dispatch({ type: FETCH_BLACKLIST });

        const oParameter = { page, mobile: isMobile ? 1 : 0 };
        if (word) {
            oParameter.word = word;
            oParameter.type = type;
        }
        const szParameter = querystring.stringify(oParameter);
        fetch(`${ROOT_URL}/${global.bj_id}/blacklist?${szParameter}`, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_BLACKLIST_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_BLACKLIST_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 블랙리스트 삭제
 * @param idx 삭제할 idx
 * @returns {Function}
 */
export function deleteBlacklist(idx, page, word = "", type = "black_id") {
    return (dispatch) => {
        fetch(`${ROOT_URL}/${global.bj_id}/blacklist/${idx}`, {
            method: POST,
            headers: FORM_CONTENT_TYPE,
            body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), 
                _method: DELETE, 
                mobile: isMobile ? 1 : 0, 
                page: page,
                word: word,
                type: type,
            }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                toastr.success(i18next.t("해제되었습니다."), {
                    timeOut: 2000,
                });
                dispatch({
                    type: DELETE_BLACKLIST_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
                dispatch({
                    type: DELETE_BLACKLIST_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 블랙리스트 추가
 * @param data	유저 데이터
 * @returns {Function}
 */
export function storeBlacklist(data) {
    return (dispatch) => {
        fetch(`${ROOT_URL}/${global.bj_id}/blacklist`, {
            method: POST,
            headers: FORM_CONTENT_TYPE,
            body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), user_id: data.user_id }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: STORE_BLACKLIST_SUCCESS,
                    payload: response,
                });

                if (isMobile) {
                    dispatch(setBlacklistPost(false));
                }

                toastr.success(i18next.t("추가되었습니다."), {
                    timeOut: 2000,
                });
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
                dispatch({
                    type: STORE_BLACKLIST_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 블랙리스트 메모 추가
 * @param data	메모 데이터
 * @returns {Function}
 */
export function updateBlacklistMemo(idx, black_memo, page, word = "", type = "black_id") {
    return (dispatch) => {
        fetch(`${ROOT_URL}/${global.bj_id}/blacklist/${idx}`, {
            method: POST,
            headers: FORM_CONTENT_TYPE,
            body: querystring.stringify({
                _token: cookie.load("XSRF-TOKEN"),
                _method: PUT,
                mobile: isMobile ? 1 : 0,
                black_memo: black_memo,
                page: page,
                word: word,
                type: type
            }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: UPDATE_BLACKLIST_SUCCESS,
                    payload: response,
                });

                toastr.success(i18next.t("저장이 완료되었습니다."), {
                    timeOut: 2000,
                });
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
                dispatch({
                    type: UPDATE_BLACKLIST_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 추가 페이지 세팅
 * @param bPost	추가 페이지 여부
 * @returns {{payload: *, type: string}}
 */
export const setBlacklistPost = (bPost) => ({ type: SET_BLACKLIST_POST, payload: bPost });

/**
 * 블랙리스트 메모 추가 페이지 세팅
 * @param bMemo	메모 추가 페이지 여부
 * @returns {{payload: *, type: string}}
 */
export const setBlacklistMemo = (bMemo) => ({ type: SET_BLACKLIST_MEMO, payload: bMemo });
