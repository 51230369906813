import { mapToToastrMessage } from "./utils.js";
import EventEmitter from "eventemitter3";
import { getWebviewVersion } from "helpers.js";
import { isMobile } from "react-device-detect";
import { isWebview } from "helpers.js";
import { versionCompare } from "helpers.js";
const emitter = new EventEmitter();
const addToToastr = (type, array) => {
	const obj = mapToToastrMessage(type, array);
	if (isMobile && isWebview) {
		const isNew = versionCompare(getWebviewVersion(), '4.3.0') >= 0 ? true : false
		if (isNew) {
			window.location.href = "afreeca://toast?msg=" + encodeURIComponent(obj.message);
		} else {
			emitter.emit("add/toastr", obj)
		}
	} else {
		emitter.emit("add/toastr", obj)
	}
};

let actions = {};
["info", "success", "warning", "error"].forEach((item) => {
	actions[item] = (...args) => addToToastr(item, args);
});

actions.clean = () => emitter.emit("clean/toastr");

/**
 * @params: can be a ID or a object that with a property type: 'success'
 * and by passing this we will remove all toastr with that type.
 */
actions.removeByType = (type) => emitter.emit("removeByType/toastr", type);

actions.remove = (id) => emitter.emit("remove/toastr", id);

export const EE = emitter;
export const toastrEmitter = actions;
