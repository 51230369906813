import { toastr } from "../components/toastr";
import querystring from "querystring";
import i18next from "i18next";
import cookie from "react-cookies";
import { isMobile } from "react-device-detect";
import { ROOT_URL } from "./config";
import { handleFailedToFetch, handleFetchErrors } from "../helpers";
import { DELETE, POST, PUT } from "../constant/method";

//Banword
//Banword list
export const FETCH_BANWORD = "FETCH_BANWORD";
export const FETCH_BANWORD_SUCCESS = "FETCH_BANWORD_SUCCESS";
export const FETCH_BANWORD_FAILURE = "FETCH_BANWORD_FAILURE";
//Create Banword
export const STORE_BANWORD_SUCCESS = "STORE_BANWORD_SUCCESS";
export const STORE_BANWORD_FAILURE = "STORE_BANWORD_FAILURE";
//Delete Banword
export const DELETE_BANWORD = "DELETE_BANWORD";
export const DELETE_BANWORD_SUCCESS = "DELETE_BANWORD_SUCCESS";
export const DELETE_BANWORD_FAILURE = "DELETE_BANWORD_FAILURE";
//Update Banword Status
export const UPDATE_BANWORD_STATUS = "UPDATE_BANWORD_STATUS";
export const UPDATE_BANWORD_STATUS_SUCCESS = "UPDATE_BANWORD_STATUS_SUCCESS";
export const UPDATE_BANWORD_STATUS_FAILURE = "UPDATE_BANWORD_STATUS_FAILURE";

export function fetchBanword(page = 1, keyword = "", is_init = true) {
	return (dispatch) => {

		const params = { page, mobile: isMobile ? 1 : 0 };
		const szParameter = querystring.stringify(params);
		
		if (is_init) {
			dispatch({ type: FETCH_BANWORD });
		}
		fetch(`${ROOT_URL}/${global.bj_id}/setting/bankeyword?${szParameter}&keyword=${window.encodeURIComponent(keyword)}`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_BANWORD_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_BANWORD_FAILURE,
					payload: error ? error : { code: 0, message: error.message },
				});
			});
	};
}

export function storeBanword(props) {
	const data = { ...props, _token: cookie.load("XSRF-TOKEN"), mobile: isMobile ? 1 : 0 };
	return fetch(`${ROOT_URL}/${global.bj_id}/setting/bankeyword`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify(data),
		credentials: "include",
	});
}

export function deleteBanword(idx, page, keyword) {
	return (dispatch) => {
		dispatch({ type: DELETE_BANWORD });
		fetch(`${ROOT_URL}/${global.bj_id}/setting/bankeyword/${idx}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), 
				_method: DELETE, 
				mobile: isMobile ? 1 : 0,
				page: page,
				keyword: keyword,
			 }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				toastr.success(i18next.t("삭제 되었습니다."), {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_BANWORD_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_BANWORD_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function updateBanwordStatus(status) {
	return (dispatch) => {
		dispatch({ type: UPDATE_BANWORD_STATUS });
		fetch(`${ROOT_URL}/${global.bj_id}/setting/bankeyword/status`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: PUT, _status: status, mobile: isMobile ? 1 : 0 }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				toastr.success(i18next.t("변경 되었습니다."), {
					timeOut: 2000,
				});
				dispatch({
					type: UPDATE_BANWORD_STATUS_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BANWORD_STATUS_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}
