import { goLogin } from "helpers";
import { scriptLoad, callAU } from "libs/scriptLoad";
import store from "store";

/**
 * 스튜디오 실행
 */
export const runNewStudio = () => {
    const { is_login } = store.getState().authenticationReducer;
    if (is_login) {
        //환경설정값
        scriptLoad("//res.afreecatv.com/script/new_main/_config.js", { charset: "euc-kr" });
        //require 모듀 추가
        scriptLoad(`https://requirejs.org/docs/release/2.3.6/comments/require.js`, () => {
            window.requirejs.config({
                baseUrl: "//static.afreecatv.com/asset",
                paths: {
                    jquery: ["library/jquery/1.10.2/jquery.min", "//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min"],
                    doT: "app/main/doT.min",
                    atv: "library/afreeca/atv.min-1.6",
                    afreeca: "service/afreeca.min-0.7",
                    "service/plugins/player": "service/afreeca.min-0.7",
                },
            });
            window.requirejs(["afreeca", "service/plugins/player"], (afreeca, afreecaPlayer) => {
                if (navigator.userAgent.replace(/ /g, "").indexOf("MacOSX") != -1) {
                    alert("현재 MAC OS에서는 웹 플레이어만 지원됩니다.");
                    return;
                }

                afreecaPlayer.player.runNewStudio("cast");
                callAU("TRY_NEW_STUDIO");
            });
        });
    } else {
        //로그인후에 스튜디오 실행을 위한 값 남김
        sessionStorage.setItem("isFreecShotRun", "true");
        goLogin();
    }
};

/**
 * 스튜디오 실행버튼으로 로그인된 경우  스튜디오바로 실행하기
 */
export const startupStudio = () => {
    if (Boolean(sessionStorage.getItem("isFreecShotRun"))) {
        runNewStudio();
        sessionStorage.removeItem("isFreecShotRun");
    }
};
