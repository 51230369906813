import produce from "immer";

import {
    FETCH_SIGNATURE_EMOTICON_LIST,
    FETCH_SIGNATURE_EMOTICON_LIST_FAILURE,
    FETCH_SIGNATURE_EMOTICON_LIST_SUCCESS,
} from "actions/emoticon.signature.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
    status: CLEAR,
    path: "",
    data: null,
    error: null,
};

const emoticonSignatureReducer = produce((state, { type, payload }) => {
    switch (type) {
        // 시그니처 이모티콘 리스트 로딩 시작
        case FETCH_SIGNATURE_EMOTICON_LIST:
            state.status = LOADING;
            break;

        // 시그니처  이모티콘 리스트 로딩 완료
        case FETCH_SIGNATURE_EMOTICON_LIST_SUCCESS:
            const { data, path } = payload;

            state.status = SUCCESS;
            state.data = data;
            state.path = path;

            // order no 에 맞게 정렬
            if (state.data && Array.isArray(state.data)) {
                state.data.sort(({ order_no: prevOrder }, { order_no: nextOrder }) => (parseInt(prevOrder) < parseInt(nextOrder) ? -1 : 1));
            }

            break;

        // 시그니처 이모티콘 로딩 실패
        case FETCH_SIGNATURE_EMOTICON_LIST_FAILURE:
            state.status = ERROR;
            state.error = payload;
            break;

        default:
    }
}, INITIAL_STATE);

export default emoticonSignatureReducer;
