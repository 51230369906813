let clickHandlers = {};

//이벤트 리스너 캐시를 이용한 React 성능 향상
//https://github.com/nhnent/fe.javascript/wiki/%23176-:-%EC%BA%90%EC%8B%9C%EB%A5%BC-%EC%9D%B4%EC%9A%A9%ED%95%9C-React-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EB%A6%AC%EC%8A%A4%EB%84%88-%EC%84%B1%EB%8A%A5-%ED%96%A5%EC%83%81
export const DEFAULT_PROFILE_IMAGE_URL = "//res.afreecatv.com/images/afmain/img_thumb_profile.gif";
export function handleProfileOnerror() {
	// 고유 식별자에 대한 이벤트 핸들러를 만들지 않았다면, 새로 생성한다.
	if (!Object.prototype.hasOwnProperty.call(clickHandlers, "Profile")) {
		clickHandlers["Profile"] = (e) => {
			e.target.src = DEFAULT_PROFILE_IMAGE_URL;
		};
	}
	return clickHandlers["Profile"];
}

export const DEFAULT_THUMBNAIL_IMAGE_URL = "//res.afreecatv.com/images/afmain/img_thumb_defalut.gif";
export function handleThumbnailOnerror() {
	// 고유 식별자에 대한 이벤트 핸들러를 만들지 않았다면, 새로 생성한다.
	if (!Object.prototype.hasOwnProperty.call(clickHandlers, "thumbnail")) {
		clickHandlers["thumbnail"] = (e) => {
			e.target.src = DEFAULT_THUMBNAIL_IMAGE_URL;
		};
	}
	return clickHandlers["thumbnail"];
}
