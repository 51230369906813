import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { ROOT_URL } from "./config";
import { STBBS_URL } from "constant/config";
import { LOGIN_SUCCESS, LOGING_FAILURE } from "constant/auth";
import {oRedirect} from "constant/redirect";

import axios from "libs/axios";
import qs from "qs";
import { toastr } from "components/toastr";

export function loginSuccess(info) {
	////console.log(info);
	return {
		type: LOGIN_SUCCESS,
		info,
	};
}

export function loginFailure(error) {
	return {
		type: LOGING_FAILURE,
		error,
	};
}
export function authenticationCheck() {
	return (dispatch) => {
		let login_id = '';
		// API REQUEST
		return fetch(`${ROOT_URL}/authentication`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				// SUCCEED
				dispatch({
					type: LOGIN_SUCCESS,
					plyload: response,
				});

				// 로그인한 아이디가 있을 경우 login_id에 넣어줌
				login_id = response.user_id;

			})
			.catch((error) => {
				// FAILED
				dispatch(loginFailure(error));
			}).finally(() => {

					// 로그인한 유저가 방송국 주인이 아닌 경우
					if (login_id !== global.bj_id) {

						// 해당 방송국이 리다이렉트 처리 대상일 경우
						// 로그인 정보가 비동기로 조회해오므로
						if (!(global.bj_id === undefined || global.bj_id === '') && Object.keys(oRedirect).includes(global.bj_id)) {
							window.location.href = oRedirect[global.bj_id];
						}
					}
				}
			);
	};
}

/**
 * 태그 작성자와, 게시글 작성자 및 관계 조회 API
 * @param {string} userId 
 * @param {string} targetId 
 * @param {string} postWriteId 
 * @param {number} authNo 
 */
export const postAuthCheck =  async(userId, targetId, postWriteId, authNo, tagExist, message) => {
    const params = {
        title_user_id: postWriteId,
        writer_id: userId,
        tag_id: targetId,
        auth_no: authNo
    }

	try {
		if(tagExist) {
			throw new Error(message);
		}
		const response = await axios.post(
			`${STBBS_URL}/api/relationshipAuthCheck.php`, qs.stringify(params), { headers: { "Content-Type": "application/x-www-form-urlencoded"},  withCredentials: false}
		);
	
		if(response.code < 0) {
			throw new Error(response.message);
		}
		return response;
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
		return {
			code: -1, auth_check: false
		};
	}


}
