import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from "classnames";

import { sendNote } from "helpers";
import { searchLog } from "libs/searchLogCollection";

import { destroyFavorite, showFavorite, userFavorite } from "actions/station.action";

import { STATION_URL } from "constant/config";
import { NICKNAME_USER_ID } from "constant/search";
import { STYPE_USER_ID } from "constant/log";

import ReactPopup from "components/popup/ReactPopup";

class Nickname extends Component {
	static defaultProps = {
		left: null,
		right: null,
		top: null,
		bottom: null,
	};
	constructor(props) {
		super(props);
		this.id = Math.random();
		this.state = { open: false, is_favorite: false };
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open_id !== nextProps.open_id) {
			this.setState({
				open: false,
			});
		}
		if (this.props.open !== nextProps.open) {
			this.setState({
				open: nextProps.open,
			});
		}
	}

	handleClick = (e) => {
		this.setState({
			open: false,
		});
	};

	handleGoStation = () => {
		const { item } = this.props;
		window.open(`${STATION_URL}/${item.user_id}`);
	};

	handleSendNote = () => {
		const { item } = this.props;
		setTimeout(() => {
			sendNote(item.user_id);
		});
	};

	handleFavorite = () => {
		const { item } = this.props;
		if (this.state.is_favorite) {
			this.props.destroyFavorite(item.user_id);
		} else {
			userFavorite(item.user_id);
		}
	};
	handleSearch = () => {
		const { history, item } = this.props;
		searchLog({keyword: item.user_id, filter: NICKNAME_USER_ID, searchType: STYPE_USER_ID});
		history.push(`/${global.bj_id}/search?keyword=${item.user_id}&type=total&field=${NICKNAME_USER_ID}`, { searchType: STYPE_USER_ID });
	};

	handlePopupOpen = () => {
		const { item } = this.props;
		showFavorite(item.user_id, (response) => {
			this.setState({
				is_favorite: response.is_favorite,
			});
		});

		this.setState({
			open: true,
		});
		if (this.props.onOpen) {
			this.props.onOpen(item.user_id);
		}
	};

	handleModalOpen = (e) => {
		const { item, authenticationReducer } = this.props;
		const { user_id } = authenticationReducer;
		if (user_id !== item.user_id) {
			showFavorite(item.user_id, (response) => {
				this.setState({
					is_favorite: response.is_favorite,
				});
			});
		}

		const rect = e.currentTarget.getBoundingClientRect();
		this.setState({
			open: true,
			style: {
				position: "absolute",
				left: rect.left,
				top: rect.top + rect.height,
			},
		});
	};

	handleClose = () => {
		this.setState({
			open: false,
		});
	};
	render() {
		const { t, offsetX, offsetY, stopPropagation, item, authenticationReducer } = this.props;
		const { user_id } = authenticationReducer;
		const { is_favorite } = this.state;
		const { left, right, top, bottom } = this.props;
		if (isMobile) {
			return (
				<ReactPopup
					open={this.state.open}
					onOpen={this.handlePopupOpen}
					trigger={this.props.children}
					stopPropagation={stopPropagation}
					on="hover"
				>
					<div className="icon-menu" style={{ position: "absolute", left, right, top, bottom }}>
						<button type="button" className="bj_bs" tip={t("방송국")} onClick={this.handleGoStation}>
							<span>
								<Trans>방송국 가기</Trans>
							</span>
						</button>
						{user_id !== item.user_id && (
							<button type="button" className="send_memo" tip={t("쪽지 보내기")} onClick={this.handleSendNote}>
								<span>
									<Trans>쪽지 보내기</Trans>
								</span>
							</button>
						)}
						{user_id !== item.user_id && (
							<button
								type="button"
								className={classNames("favor", { on: is_favorite })}
								tip={is_favorite ? t("즐겨찾기 삭제") : t("즐겨찾기 추가")}
								onClick={this.handleFavorite}
							>
								<span>
									<Trans>즐겨찾기</Trans>
								</span>
							</button>
						)}

						<button type="button" className="srh" tip={t("방송국 게시글/VOD 검색")} onClick={this.handleSearch}>
							<span>
								<Trans>방송국 게시글/VOD 검색</Trans>
							</span>
						</button>
					</div>
				</ReactPopup>
			);
		} else {
			return (
				<ReactPopup
					open={this.state.open}
					onOpen={this.handlePopupOpen}
					trigger={this.props.children}
					stopPropagation={stopPropagation}
					on="hover"
				>
					<div className="icon-menu" style={{ position: "absolute", top: 25 + offsetY, left: offsetX, right: this.props.right }}>
						<button type="button" className="bj_bs" tip={t("방송국")} onClick={this.handleGoStation}>
							<span>
								<Trans>방송국 가기</Trans>
							</span>
						</button>
						{user_id !== item.user_id && (
							<button type="button" className="send_memo" tip={t("쪽지 보내기")} onClick={this.handleSendNote}>
								<span>
									<Trans>쪽지 보내기</Trans>
								</span>
							</button>
						)}
						{user_id !== item.user_id && (
							<button
								type="button"
								className={classNames("favor", { on: is_favorite })}
								tip={is_favorite ? t("즐겨찾기 삭제") : t("즐겨찾기 추가")}
								onClick={this.handleFavorite}
							>
								<span>
									<Trans>즐겨찾기</Trans>
								</span>
							</button>
						)}

						<button type="button" className="srh" tip={t("방송국 게시글/VOD 검색")} onClick={this.handleSearch}>
							<span>
								<Trans>방송국 게시글/VOD 검색</Trans>
							</span>
						</button>
					</div>
				</ReactPopup>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		authenticationReducer: state.authenticationReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		destroyFavorite: (bj_id) => {
			dispatch(destroyFavorite(bj_id));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(withTranslation()(Nickname)));
