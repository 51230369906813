import axios from "libs/axios";
import querystring from "querystring";
import i18next from "i18next";
import { FORM_CONTENT_TYPE, ROOT_URL } from "./config";
import { isMobile } from "react-device-detect";
import { DELETE, POST } from "constant/method";
import cookie from "react-cookies";
import { toastr } from "components/toastr";


export const FETCH_USERCLIP_BLACKLIST = "FETCH_USERCLIP_BLACKLIST";
export const FETCH_USERCLIP_BLACKLIST_SUCCESS = "FETCH_USERCLIP_BLACKLIST_SUCCESS";
export const FETCH_USERCLIP_BLACKLIST_FAILURE = "FETCH_USERCLIP_BLACKLIST_FAILURE";

export const STORE_USERCLIP_BLACKLIST = "STORE_USERCLIP_BLACKLIST";
export const STORE_USERCLIP_BLACKLIST_SUCCESS = "STORE_USERCLIP_BLACKLIST_SUCCESS";
export const STORE_USERCLIP_BLACKLIST_FAILURE = "STORE_USERCLIP_BLACKLIST_FAILURE";

export const DELETE_USERCLIP_BLACKLIST = "DELETE_USERCLIP_BLACKLIST";
export const DELETE_USERCLIP_BLACKLIST_SUCCESS = "DELETE_USERCLIP_BLACKLIST_SUCCESS";
export const DELETE_USERCLIP_BLACKLIST_FAILURE = "DELETE_USERCLIP_BLACKLIST_FAILURE";

export const SET_USERCLIP_BLACKLIST_POST = "SET_USERCLIP_BLACKLIST_POST";

export const fetchUserClipBlackList = (page = 1, word = "", type = "black_id") => async (dispatch) => {
    dispatch({type: FETCH_USERCLIP_BLACKLIST});
    const oParameter = { page, mobile: isMobile ? 1 : 0};
    if (word) {
        oParameter.word = word;
        oParameter.type = type;
    }

    const szParameter = querystring.stringify(oParameter);
    try {
        let response = await axios.get(`${ROOT_URL}/${global.bj_id}/setting/userclip-blacklist?${szParameter}`);
        console.log(response);
        dispatch({
            type: FETCH_USERCLIP_BLACKLIST_SUCCESS,
            payload: response,
        });
    } catch (e) {
        dispatch({
            type: FETCH_USERCLIP_BLACKLIST_FAILURE,
            payload: e ? e : { code: 0, message: e },
        });
    }
}

export const storeUserClipBlackList = (data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${ROOT_URL}/${global.bj_id}/setting/userclip-blacklist`, 
                querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), user_id: data.user_id}),
            );
            
        toastr.success(i18next.t("추가되었습니다."), {
            timeOut: 2000,
        });
        console.log(response);
        dispatch({
            type: STORE_USERCLIP_BLACKLIST_SUCCESS,
            payload: response
        });
    } catch(e) {
        toastr.success(i18next.t(e.message), {
            timeOut: 2000,
        });
    }
}


export const deleteUserClipBlackList = (idx = "all", page, word ="", type ="black_id") => async (dispatch)  => {
    try {
        const response = await axios.delete(`${ROOT_URL}/${global.bj_id}/setting/userclip-blacklist/${idx}`, {
            data: {
                    _token: cookie.load("XSRF-TOKEN"),
                    mobile: isMobile ? 1 : 0,
                    page: page,
                    word: word,
                    type: type,
                }
            }
        );
        
        dispatch({
                type: DELETE_USERCLIP_BLACKLIST_SUCCESS,
                payload: response,
        });

        toastr.success(i18next.t("해제되었습니다."), {
            timeOut: 2000,
        });

    } catch(e) {
        toastr.success(i18next.t(e.message), {
            timeOut: 2000,
        });
    }
}
