import querystring from "querystring";
import cookie from "react-cookies";
import i18next from "i18next";

import { toastr } from "../components/toastr";
import { ROOT_URL } from "./config";
import { PUT, DELETE, POST } from "../constant/method";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { isMobile } from "react-device-detect";

export const FETCH_SETTING_MENU = "FETCH_SETTING_MENU";
export const FETCH_SETTING_MENU_SUCCESS = "FETCH_SETTING_MENU_SUCCESS";
export const FETCH_SETTING_MENU_FAILURE = "FETCH_SETTING_MENU_FAILURE";

export function fetchSettingMenu(page = 1) {
	return (dispatch) => {
		dispatch({ type: FETCH_SETTING_MENU });

		fetch(`${ROOT_URL}/${global.bj_id}/setting/menu`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_SETTING_MENU_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_SETTING_MENU_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export const STORE_SETTING_MENU = "STORE_SETTING_MENU";
export const STORE_SETTING_MENU_SUCCESS = "STORE_SETTING_MENU_SUCCESS";
export const STORE_SETTING_MENU_FAILURE = "STORE_SETTING_MENU_FAILURE";

export function storeSettingMenu(name, auth_no, wauth, display_type, rnum, callback_action = "") {
	return (dispatch) => {
		dispatch({ type: STORE_SETTING_MENU });
		const write_auth = JSON.stringify(wauth);

		fetch(`${ROOT_URL}/${global.bj_id}/setting/menu`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), name, auth_no, write_auth, display_type, rnum }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: STORE_SETTING_MENU_SUCCESS,
					payload: response,
				});
				toastr.success(response.message, {
					timeOut: 2000,
				});
				if (isMobile && callback_action !== "") {
					dispatch(callback_action);
				}
			})
			.catch((error) => {
				const message = error.message ? error.message : error;
				toastr.error(message, {
					timeOut: 2000,
				});
				dispatch({
					type: STORE_SETTING_MENU_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export const PUT_SETTING_MENU = "PUT_SETTING_MENU";
export const PUT_SETTING_MENU_SUCCESS = "PUT_SETTING_MENU_SUCCESS";
export const PUT_SETTING_MENU_FAILURE = "PUT_SETTING_MENU_FAILURE";

export function updateSettingMenu(bbs_no, name, auth_no, wauth, callback_action = "") {
	return (dispatch) => {
		dispatch({ type: PUT_SETTING_MENU });
		const write_auth = JSON.stringify(wauth);

		fetch(`${ROOT_URL}/${global.bj_id}/setting/menu/${bbs_no}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: PUT, name, auth_no, write_auth }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: PUT_SETTING_MENU_SUCCESS,
					payload: response,
				});
				toastr.success(response.message, {
					timeOut: 2000,
				});
				if (isMobile && callback_action !== "") {
					dispatch(callback_action);
				}
			})
			.catch((error) => {
				const message = error.message ? error.message : error;
				toastr.error(message, {
					timeOut: 2000,
				});
				dispatch({
					type: PUT_SETTING_MENU_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export const CREATE_SETTING_MENU = "CREATE_SETTING_MENU";
export function createSettingMenu(display_type) {
	return (dispatch) => {
		dispatch({
			type: CREATE_SETTING_MENU,
			payload: {
				name: "",
				auth_no: 104,
				mode: true,
				write_auth: {
					write_all: true,
					write_fanclub: true,
					write_favorite: true,
					write_manager: true,
					write_subscription: true,
				},
				display_type,
			},
		});
	};
}

export const DELETE_SETTING_MENU_SUCCESS = "DELETE_SETTING_MENU_SUCCESS";
export const DELETE_SETTING_MENU_FAILURE = "DELETE_SETTING_MENU_FAILURE";

export function destroySettingMenu(bbs_no, callback_action = "") {
	return (dispatch) => {
		fetch(`${ROOT_URL}/${global.bj_id}/setting/menu/${bbs_no}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: DELETE_SETTING_MENU_SUCCESS,
					payload: response,
				});
				toastr.success(response.message, {
					timeOut: 2000,
				});
				if (isMobile && callback_action !== "") {
					dispatch(callback_action);
				}
			})
			.catch((error) => {
				const message = error.message ? error.message : error;
				toastr.error(message, {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_SETTING_MENU_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export const POST_SORT_MENU = "POST_SORT_MENU";
export const POST_SORT_MENU_SUCCESS = "POST_SORT_MENU_SUCCESS";
export const POST_SORT_MENU_FAILURE = "POST_SORT_MENU_FAILURE";

export function updateSortMenu(orders) {
	return (dispatch) => {
		const ordersJson = JSON.stringify(orders);
		fetch(`${ROOT_URL}/${global.bj_id}/setting/menu/sort`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), orders: ordersJson }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: POST_SORT_MENU_SUCCESS,
					payload: response,
				});
				toastr.success(i18next.t("게시판 순서가 변경되었습니다."), {
					timeOut: 2000,
				});
			})
			.catch((error) => {
				dispatch({
					type: POST_SORT_MENU_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export const POSTS_VOD_MOVE_SUCCESS = "POSTS_VOD_MOVE_SUCCESS";
export const POSTS_VOD_MOVE_FAILURE = "POSTS_VOD_MOVE_FAILURE";

/**
 * 방송국 관리 > 메뉴 수정시 권한 select box 선택할때 VOD 여부 체크 API
 * @param {*} bbsNo 
 * @param {*} authNo 
 * @returns 
 */
export function postsVODMoveCheck (bbsNo, authNo) {
	return (dispatch) => {
		fetch(`${ROOT_URL}/${global.bj_id}/setting/menu/vodCheck?bbs_no=${bbsNo}&auth_no=${authNo}`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: POSTS_VOD_MOVE_SUCCESS,
					payload: response,
				});

				toastr.success(response.message, {
					timeOut: 2000,
				});
			})
			.catch((error) => {
				dispatch({
					type: POSTS_VOD_MOVE_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});

				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	}
}
