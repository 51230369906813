import { getPlatForm } from "helpers";
import MD5 from "md5.js";
import querystring from "querystring";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import cookie from "react-cookies";
import { isAndroid, isMobile } from "react-device-detect";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { fetchAd, adSetlog, CLEAR_AD } from "actions/ad.action";
import { CLEAR, ERROR } from "constant/status";
import { GOOGLE, SDK, TYPE_PC_MAIN_BANNER, getPlacement, getStationHomeAdInfo, MOBILE_AD_TEXT_BANNER, PC_IMAGE_BANNER } from "constant/ad";
import { adBannerInfo } from "actions/adBanner.action";

export default function AdComponent({ code }) {
    const dispatch = useDispatch();

    const [config, setConfig] = useState({
        type: GOOGLE,
        code: "",
        google_ad_id: "",
        is_empty: false,
    });

    const [adBanner, setAdBannerInfo] = useState({
        code: "",
        type: "",
        items: [],
        is_empty: true,
    });

    // PdBoxUser 쿠키조회
    const getCookieParse = useCallback((key) => {
        let szPdboxUser = cookie.load("PdboxUser");
        if (szPdboxUser == null) return;
        try {
            let aPdboxUser = decodeURIComponent(szPdboxUser).split("&");
            let aVal = aPdboxUser
                .map(function(data) {
                    return data.split("=");
                })
                .filter(function(data) {
                    return data[0] === key;
                });
            return aVal[0][1];
        } catch (e) {
            return;
        }
    }, []);

    // 모바일 화면방향
    const getOrientation = useCallback(() => {
        if (isMobile) {
            if (window.orientation === 0 || window.orientation === 180) {
                return "PORTRAIT";
            } else if (window.orientation === 90 || window.orientation === -90) {
                return "LANDSCAPE";
            }
        } else {
            return "";
        }
    }, []);

    // 개인화 데이터 조건
    const getDemo = useCallback(() => {
        let demo = 90000;
        const sex = getCookieParse("sex");
        const age = getCookieParse("age");

        if (
            cookie.load("PdboxUser") !== null &&
            cookie.load("NOCUSTOMIZEDAD") !== 1 &&
            (sex !== undefined && sex !== "C") &&
            age !== undefined
        ) {
            //성별
            if (sex === "A") {
                demo = 7; //남자
            } else if (sex === "B") {
                demo = 8; //여자
            } else {
                demo = 9; //기타
            }

            //연령대별 16진수값 변환
            if (14 <= age && age <= 18) {
                demo += Number(14).toString(16) + Number(18).toString(16);
            } else if (19 <= age && age <= 24) {
                demo += Number(19).toString(16) + Number(24).toString(16);
            } else if (25 <= age && age <= 29) {
                demo += Number(25).toString(16) + Number(29).toString(16);
            } else if (30 <= age && age <= 34) {
                demo += Number(30).toString(16) + Number(34).toString(16);
            } else if (35 <= age && age <= 39) {
                demo += Number(35).toString(16) + Number(39).toString(16);
            } else if (40 <= age && age <= 44) {
                demo += Number(40).toString(16) + Number(44).toString(16);
            } else if (45 <= age && age <= 49) {
                demo += Number(45).toString(16) + Number(49).toString(16);
            } else if (50 <= age && age <= 54) {
                demo += Number(50).toString(16) + Number(54).toString(16);
            } else if (55 <= age && age <= 55) {
                demo += Number(55).toString(16) + Number(55).toString(16);
            } else if (60 <= age) {
                demo += Number(60).toString(16) + Number(60).toString(16);
            } else {
                demo += "0000";
            }
        }
        return demo;
    }, [getCookieParse]);
    
    const { bannerControlArr = {} } = useSelector((state) => state.adBannerReducer, shallowEqual);
    const { bannerOnOff = false, mainBannerOnOff = false } = bannerControlArr;
    
    const { user_id } = useSelector((state) => state.authenticationReducer, shallowEqual);
    const { stationInfo } = useSelector((state) => state.stationReducer, shallowEqual);

    const { is_adsence = false, country = "KR" } = stationInfo.data;
    const { status = CLEAR, data = [], items = [], error = false } = useSelector((state) => state.adReducer, shallowEqual);

    const { type = null, ad_network_number_sdk } = data;
    const creatives = useMemo(() => {
        return items.find((items) => items.code === code) || {};
    }, [code, items]);

    // 노출 배너정보 세팅
    useEffect(() => {
        if (Object.keys(creatives).length > 0) {
            if (adBanner.is_empty) {
                setAdBannerInfo({
                    code: creatives.code,
                    type: type,
                    items: creatives,
                    is_empty: false,
                });
            }
        }
    }, [adBanner.is_empty, creatives, type]);

    // 광고 호출 공통 파라미터
    const aftvUserId = cookie.load("_au");
    const userLang = navigator.language || navigator.userLanguage || "";
    const dlang = userLang.substr(0, 2).toUpperCase();

    // 광고API 호출 파라미터
    const adParameters = useCallback(() => {
        const unk = "UNKNOWN";
        const au = cookie.load("NOCUSTOMIZEDAD") === "1" ? false : true;
        const deviceWidth = window.screen.width || unk;
        const deviceHeight = window.screen.height || unk;
        const placement = getPlacement(code, isMobile, isAndroid);
        const platform = getPlatForm() || unk;
        const demo = getDemo() || 90000;
        const orientation = getOrientation() || unk;

        return querystring.stringify({
            publisher: "AFREECA",
            placement: placement,
            btype: "STATION",
            au: au,
            "user-id": aftvUserId || unk,
            "login-id": user_id || unk,
            country: country || unk,
            language: dlang || unk,
            bj: global.bj_id || unk,
            platform: platform,
            application: "WEB",
            "in-media": true,
            demo: demo,
            "device-resolution-width": deviceWidth,
            "device-resolution-height": deviceHeight,
            orientation: orientation,
        });
    }, [aftvUserId, code, country, dlang, getDemo, getOrientation, user_id]);

    // GPT 호출 파라미터
    const getParameters = useCallback(() => {
        const aftvlid = user_id ? new MD5().update(user_id).digest("hex") : "";
        return querystring.stringify({
            oax: cookie.load("OAX"),
            aftvid: aftvUserId,
            aftvlid: aftvlid,
            aftvbjid: global.bj_id,
            dlang: dlang,
        });
    }, [aftvUserId, dlang, user_id]);

    // 광고 on/off 호출
    useEffect(() => {
        if (Object.keys(bannerControlArr).length !== 0) return;
        adBannerInfo();
    }, []);

    // 광고API 호출
    useEffect(() => {
        if (code !== "x50" && is_adsence === false) return; //code x50은 is_adsence와 관련 없음
        if (Object.keys(bannerControlArr).length === 0) return;
        if (code === "x50" && mainBannerOnOff === false) return; //모바인 하단 메인 배너, pc 사이드 메인 배너
        if (code !== "x50" && bannerOnOff === false) return;//

        dispatch(fetchAd(adParameters(), code));

        return () => {
            dispatch({ type: CLEAR_AD });
        };
    }, [bannerControlArr, is_adsence]);

    // 구글광고 호출
    useEffect(() => {
        if (status === ERROR || is_adsence === false || adBanner.type !== SDK || ad_network_number_sdk !== 0 ) {
            return;
        }

        window.googletag = window.googletag || {};
        window.googletag.cmd = window.googletag.cmd || [];
        window.googletag_id = window.googletag_id || 0;

        if (config.type === GOOGLE) {
            const { type, google_ad_client, google_ad_slot } = getStationHomeAdInfo(code);

            window.googletag_id++; //호출시마다 증가시킴
            const google_ad_id = `${google_ad_client}-${window.googletag_id}`;

            window.googletag.cmd.push(() => {
                window.googletag.defineSlot(google_ad_slot, ["fluid"], google_ad_id).addService(window.googletag.pubads());
                window.googletag.pubads().set("page_url", "http://afreecatv.com");
                window.googletag.pubads().enableSingleRequest();
                window.googletag.pubads().collapseEmptyDivs();
                window.googletag.enableServices();
            });

            setConfig({
                type,
                code,
                google_ad_id,
                google_ad_slot,
                is_empty: false,
            });
        }
    }, [ad_network_number_sdk, code, getParameters, is_adsence, status, adBanner.type, config.type, bannerOnOff]);

    useEffect(() => {
        if (config.google_ad_id) {
            window.googletag.cmd.push(() => {
                window.googletag.pubads().addEventListener("slotRenderEnded", (event) => {
                    if (config.google_ad_id === event.slot.getSlotId().getDomId()) {
                        if (event.isEmpty) {
                            setConfig({ type: GOOGLE, is_empty: true });
                        }
                    }
                });
                window.googletag.display(config.google_ad_id);
            });
        }
    }, [config.google_ad_id]);

    // 광고클릭 통계호출
    const handleLog = useCallback(
        (click_url) => () => {
            adSetlog(click_url);
        },
        [],
    );

    // 응답 에러 시, 노출제외
    if (status === ERROR || type === "ERROR" || error) {
        return null;
    }
    
    if (adBanner.type === SDK) {
        if (config.type === GOOGLE) {
            return config.google_ad_slot && config.is_empty === false ? (
                <div id={config.google_ad_id} className="adsense" style={{ display: "none" }} />
            ) : null;
        } else {
            return config.link ? (
                <a href={config.link} target={config.target} rel="noopener noreferrer">
                    <img src={config.image} width="441" height="92" alt="" />
                </a>
            ) : null;
        }
    } else if (adBanner.type === TYPE_PC_MAIN_BANNER) {
        const { click_url, error_url, image_url, landing_url } = adBanner.items;
        return (
            <a href={landing_url} onClick={handleLog(click_url)} target="_blank" rel="noopener noreferrer">
                <img src={image_url} loading="lazy" width="439" height="90" onError={handleLog(error_url)} alt="" />
            </a>
        );
    } else if (adBanner.type === PC_IMAGE_BANNER) {
        const { click_url, error_url, image_url, landing_url } = adBanner.items;
        return (
            <div className="bnr_wrap2" onClick={handleLog(click_url)} onError={handleLog(error_url)}>
                <a href={landing_url} target="_blank"><img src={image_url} alt=""/></a>
            </div>
        );
    } else if (adBanner.type === MOBILE_AD_TEXT_BANNER) {
        const { click_urls, error_url, image_url, landing_url, cta, advertiser, title } = adBanner.items;
        return (
            <div className="bnr_wrap2" onClick={handleLog(click_urls[0])} onError={handleLog(error_url)}>
                <a href={landing_url}>
                    <span className="img"><img src={image_url} alt=""/></span>
                    <div className="text">
                        <em>{advertiser}</em>
                        <p>{title}</p>
                    </div>
                    <span className="btn">{cta}</span>
                </a>
            </div>
        );
    }
    
    else {
        return null;
    }
}
