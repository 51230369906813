import classNames from "classnames";
import LiveImage from "components/left/LiveImage";
import { goLive, numberCommaString } from "helpers";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { scriptLoad } from "libs/scriptLoad";


const Live = (props) => {

    const [more,setMore] = useState(false);
    const { broad } = props;
    const { broad_title, current_sum_viewer } = broad;
    const num = numberCommaString(current_sum_viewer);
    const lang = document.documentElement.lang;

    useEffect(() => {
        scriptLoad("//res.afreecatv.com/script/new_main/_config.js", { charset: "euc-kr" });
        scriptLoad(`https://requirejs.org/docs/release/2.3.6/comments/require.js`, () => {
            window.requirejs.config({
                baseUrl: "//static.afreecatv.com/asset",
                paths: {
                    jquery: ["library/jquery/1.10.2/jquery.min", "//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min"],
                    doT: "app/main/doT.min",
                    atv: "library/afreeca/atv.min-1.6",
                    afreeca: "service/afreeca.min-0.7",
                    "service/plugins/player": "service/afreeca.min-0.7",
                },
            });
        });
    })
    
    const handleRunSetupPlayer = (type) => () => {
        window.requirejs(["afreeca", "service/plugins/player"], (afreeca, afreecaPlayer) => {
            switch (type) {
                case "basecamp":
                    afreecaPlayer.player.runBasecamp();
                    break;
                default:
                    break;
            }
        });
    };

    const handleMore = () => {
        setMore(!more);
    }

    return (
        <div className="onAir_box">
            <a href="" onClick={goLive(broad)}>
                <span className="img">
                    <LiveImage broad={broad} refresh={true} />
                </span>
                <div className="top">
                    <span className="live">LIVE</span>
                    <span className="views">
                        <Trans num={num}>{{ num }}</Trans>
                    </span>
                </div>
                <p className="sbj" title={broad_title}>
                    {broad_title}
                </p>
            </a>
            <div className={classNames("watchPlayer", { active: more })}>
                <button type="button" className="btn-more" onClick={handleMore}>
                    <span>
                        <Trans>더보기</Trans>
                    </span>
                </button>
                <div className="player_list">
                    <ul>
                        {lang === "ko" && (
                            <li>
                                <button type="button" onClick={handleRunSetupPlayer("basecamp")}>
                                    <span>
                                        <Trans>VR 플레이어로 실행</Trans>
                                    </span>
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Live;
