import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { DASHBOARD, JAVASCRIPT_VOID } from "constant/config";
import { runNewStudio, startupStudio } from "libs/broadcast";

const BroadCastArea = () => {
    
    const { t } = useTranslation();
    const lang = document.documentElement.lang;
    const [isJpBroadClass,setIsJpBroadClass] = useState("");
    const { is_login } = useSelector((state) => state.authenticationReducer);

    /**
     * 로그인되었을때 스튜디오 실행
     */
    useEffect(() => {
        if (is_login === true) {
            //스튜디오 실행버튼으로 로그인이 되었으면 실행
            startupStudio();
        }
    }, [is_login]);

    useEffect(() => {
        function handleClickOutside() {
			if (isJpBroadClass === "on") {
                setIsJpBroadClass("");
			}
		}
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, [isJpBroadClass]);

    const handleJapanStudio = useCallback(() => {
        setIsJpBroadClass(isJpBroadClass == "on"? "" : "on");
    }, [isJpBroadClass]);


    const handleOpenStudio = useCallback(() => {
        if(lang === 'ja'){
            setIsJpBroadClass(isJpBroadClass === "on" ? "" : "on")
            return false;
        }
        if (lang === "ko") {
            //프릭샷 실행
            runNewStudio();
            setIsJpBroadClass("");
        } else {
            goDashBoard();
            setIsJpBroadClass("");
        }
    }, [isJpBroadClass]);

    /**
     * 외부 장치 페이지 이동
     */
    const goDashBoard = useCallback(() => {
        window.open(DASHBOARD);
    },[]);

    //일본은 프릭샷, 외부장치 선택 가능
    if(lang === "ja"){
        return (
            <div className={"jp_broadcast_wrap "+isJpBroadClass}>
                <button type="button" className="btn-broadcast" tip={t("방송하기")} onClick={handleJapanStudio}><Trans>방송하기</Trans></button>
                <ul className="broadcast_select">
                    <li className="fs"><a href={JAVASCRIPT_VOID} onClick={()=>{runNewStudio()}}>FreecShot</a></li>
                    <li className="db"><a href={JAVASCRIPT_VOID} onClick={()=>{goDashBoard()}}>Dashboard</a></li>
                </ul>
            </div>
        );
    }

    return (
            <button type="button" className="studio" tip={t("방송하기")} onClick={handleOpenStudio}>
                <span>
                    <Trans>방송하기</Trans>
                </span>
            </button>
    );

}
export default BroadCastArea;