export const YOUTUBE = 1;
export const INSTAGRAM = 2;

//인스타그램
export const INSTAGRAM_API = "https://api.instagram.com";
export const INSTAGRAM_CLIENT_ID = "3346121948759909";
export const INSTAGRAM_REDIRECT_URL = "https://bj.afreecatv.com/sns/instagram_callback.html";
export const INSTAGRAM_CLIENT_SECRET = "87be6be076811cfc24af70764079c853";

export const GOOGLE_API_KEY = "";
// Client ID and API key from the Developer Console
export const YOUTUBE_CLIENT_ID = "334347868721-ll73gpcnnr4bkohsk3qj7sf0v6fd6kq3.apps.googleusercontent.com";
// Authorization scopes required by the API. If using multiple scopes,
// separated them with spaces.
export const YOUTUBE_SCOPES = "https://www.googleapis.com/auth/youtube.readonly";
export const YOUTUBE_REDIRECT_URI = "https://bj.afreecatv.com/sns/youtube_callback.html";
