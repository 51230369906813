import { ROOT_URL } from "./config";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";

export const FETCH_SUBSCRIPT = "FETCH_SUBSCRIPT";
export const FETCH_SUBSCRIPT_SUCCESS = "FETCH_SUBSCRIPT_SUCCESS";
export const FETCH_SUBSCRIPT_FAILURE = "FETCH_SUBSCRIPT_FAILURE";

export function fetchSubscript(word = "", type = "user_id", page = 1) {
	return (dispatch) => {
		let word_url = "";
		if (word) {
			word_url = `&word=${window.encodeURIComponent(word)}&type=${type}`;
		}

		dispatch({ type: FETCH_SUBSCRIPT });

		fetch(`${ROOT_URL}/${global.bj_id}/subscript?page=${page}` + word_url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_SUBSCRIPT_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_SUBSCRIPT_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}
