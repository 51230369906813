import { POST, PUT } from "constant/method";
import { GOOGLE_API_KEY } from "constant/sns";
import fetchJsonp from "fetch-jsonp";
import { handleFetchErrors, youtubeDurationToSeconds } from "helpers";
import axios from "libs/axios";
import querystring from "querystring";
import { isMobile } from "react-device-detect";
import { ROOT_URL } from "./config";
import { SET_INSTAGRAM_FOLLOWED, SET_YOUTUBE_FOLLOWED, SET_PRIVATE_STATE } from "./station.action";
import fetchTimeout from "libs/fetchTimeout";

/**
 * 즐겨찾기 추가
 * @param {*} bj_id
 */
export function fetchSns() {
    return axios.get(`/${global.bj_id}/setting/sns`);
}

export function storeSns(data) {
    return axios.post(`/${global.bj_id}/setting/sns`, data);
}

export function destroySns(id) {
    return axios.delete(`/${global.bj_id}/setting/sns/${id}`);
}

/******************************************************************
 * 유투브
 ******************************************************************/
export const FETCH_INSTAGRAM = "FETCH_INSTAGRAM";
export const FETCH_INSTAGRAM_SUCCESS = "FETCH_INSTAGRAM_SUCCESS";
export const FETCH_INSTAGRAM_FAILURE = "FETCH_INSTAGRAM_FAILURE";
/**
 * 인스타그램 media 데이터 가져오기
 * @param id
 * @param limit
 * @param after
 */
export function dispatchFetchInstagramMedia(id, limit, after) {
    return (dispatch) => {
        dispatch({
            type: FETCH_INSTAGRAM,
        });
        fetchInstagramMedia(id, limit, after)
            .then((response) => {
                dispatch({
                    type: FETCH_INSTAGRAM_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_INSTAGRAM_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}
/**
 * 인스타그램 media 데이터 가져오기
 * @param id
 * @param limit
 * @param after
 */
export function fetchInstagramMedia(id, limit, after) {
    const params = querystring.stringify({
        id,
        limit,
        after,
    });
    return fetch(`${ROOT_URL}/${global.bj_id}/sns/instagram/media?${params}`)
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            response.data.map((item) => {
                var re1 = /\/(p|tv|reel)\/([A-Za-z0-9_\-]+)/i;
                const m1 = item.permalink.match(re1);
                //ex) /p/{id} m1, {id} m1[1]
                if (m1) {
                    item.type = m1[1]; // /p/ /tv/ /reel/ check
                    item.id = m1[2]; // id셋팅
                }
                return item;
            });
            return response;
        });
}

/**
 * 토큰 만료됐는지 확인용도
 * @param id
 */
export function fetchInstagramInvalidated(id) {
    const params = querystring.stringify({
        id,
        limit: 20,
        cache: false,
    });
    return fetch(`${ROOT_URL}/${global.bj_id}/sns/instagram/media?${params}`)
        .then(handleFetchErrors)
        .then((response) => response.json());
}

/**
 * 인스타그램 OEmbed
 * @param {*} url
 */
export function fetchInstagramOEmbed(url) {
    const params = querystring.stringify({
        url,
        omitscript: true,
        maxwidth: 658,
        access_token: "902591350265128|8b90eec0a818a3c7d2dec5db0b7bd669",
    });
    return fetch(`https://graph.facebook.com/v8.0/instagram_oembed?${params}`)
        .then(handleFetchErrors)
        .then((response) => response.json());
}

/**
 * 인스타그램 팔로워수 가져오기
 * @param {string} id
 * @param channel_id
 * @param storeFollowers
 */
export function fetchInstagramFollowed(id, channel_id, storeFollowers) {
    return async (dispatch) => {
        const followKey = `instagram_followd_by_${id}`;
        const followers = sessionStorage.getItem(followKey);

        /*
        * 기존에는 null이 아닐 경우에 dispatch, 브라우저에 저장 된 현재 팔로워수와 같을 경우에는 호출 하지 않는다.
        */
        if (followers === storeFollowers) return;

        //if (!channel_id) {
            //throw new Error("channel_id is undefined");
            //return;
        //}

        /**
         * embed api 호출해서 가져오기
         */
        await fetchInstagramFollowedByEmbed(id).then((followData) => {

            const { followers } = followData;
            // dispatch followers & private update
            dispatch({ type: SET_INSTAGRAM_FOLLOWED, followers });
            if (followers > 0 && followers !== storeFollowers) {
                updateInstagramFollowers(id, followers);
                sessionStorage.setItem(followKey, followers);
            }
        }).catch((e) => {
            //dispatch({ type: SET_PRIVATE_STATE, isPrivate : true });
            //console.log(e.message);
        });
    };
}

/**
 * embed api 호출해서 가져오기
 * @param {string} id
 * @param cache: api 캐시 적용 여부
 */
export function fetchInstagramFollowedByEmbed(id, cache = true) {
    const params = querystring.stringify({
        limit: isMobile ? 6 : 5,
        cache,
    });
    return fetch(`${ROOT_URL}/${global.bj_id}/sns/instagram/embed/${id}?${params}`)
        .then(handleFetchErrors)
        .then((response) => response.json())
}

/**
 * 팔로우수 업데이트
 * @param {string} id
 */
export function updateInstagramFollowers(id, f) {
    fetch(`${ROOT_URL}/${global.bj_id}/sns/instagram/embed/followers/${id}`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({ _method: PUT, id, f }),
        credentials: "include",
    })
        .then((response) => {})
        .catch((e) => {});
}

/******************************************************************
 * 유투브
 ******************************************************************/
export const FETCH_YOUTUBE = "FETCH_YOUTUBE";
export const FETCH_YOUTUBE_SUCCESS = "FETCH_YOUTUBE_SUCCESS";
export const FETCH_YOUTUBE_FAILURE = "FETCH_YOUTUBE_FAILURE";

/**
 * 유튜브 채널정보
 * @param {*} access_token
 */
export function fetchYoutubeChannels(access_token) {
    return axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics&mine=true&access_token=${access_token}`,
        { withCredentials: false },
    );
}
/**
 * 유튜브 업로드 영상 리스트
 * @param {string} id  플레이리스트ID (업로드)
 * @param {number} limit 노출갯수
 */
export function dispatchFetchYoutubeMedia(id, limit, pageToken) {
    return (dispatch) => {
        dispatch({
            type: FETCH_YOUTUBE,
        });

        fetchYoutubeMedia(id, limit, pageToken)
            .then((response) => {
                dispatch({
                    type: FETCH_YOUTUBE_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_YOUTUBE_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 유튜브 업로드 영상
 * @param {string} id  플레이리스트ID (업로드)
 * @param {number} limit 노출갯수
 */
export function fetchYoutubeMedia(id, limit, pageToken) {
    const params = querystring.stringify({
        playlistId: id,
        key: GOOGLE_API_KEY,
        part: "contentDetails",
        maxResults: limit,
        pageToken,
    });
    //채널리스트에 영상ID 가져오기
    //const url = `https://www.googleapis.com/youtube/v3/playlistItems?${params}`;
    const url = `${ROOT_URL}/${global.bj_id}/sns/youtube/playlistItems?${params}`;

    return fetchJsonp(url)
        .then((response) => response.json())
        .then((response) => {
            if (response.error) {
                //채널에 영상이 없으면 playlistNotFound 로 출력함
                //message: "The playlist identified with the request's <code>playlistId</code> parameter cannot be found."
                if (response.error.code === 404 && response.error.errors[0].reason === "playlistNotFound") {
                    return { items: [] };
                } else {
                    return Promise.reject(response.error);
                }
            }
            return response;
        })
        .then((response) => {
            return fetchYoutubeVideos(response); //videosItem 변경
        });
}

export function fetchYoutubeVideos(playlistItems) {
    //영상아이디 리스트로 영상정보가져오기 (재생시간,시청수 때문)
    const videoIds = playlistItems.items.map((item) => item.contentDetails.videoId);
    if (videoIds.length === 0) {
        return new Promise((resolve, reject) => resolve(playlistItems));
    }
    const ids = videoIds.join(",");

    const params = querystring.stringify({
        id: ids,
        key: GOOGLE_API_KEY,
        part: "snippet,contentDetails,statistics",
    });
    //영상아이디 리스트로 영상정보가져오기 (재생시간,시청수 때문)
    //const url = `https://www.googleapis.com/youtube/v3/videos?${params}`;
    const url = `${ROOT_URL}/${global.bj_id}/sns/youtube/videos?${params}`;
    return fetchJsonp(url)
        .then((response) => response.json())
        .then((response) => {
            playlistItems.items = response.items.map((item) => {
                item.contentDetails.duration = youtubeDurationToSeconds(item.contentDetails.duration);
                item.snippet.publishedAt = item.snippet.publishedAt.substr(0, 10).replace(/-/gi, ".");
                return item;
            });
            return playlistItems;
        });
}

export function fetchYoutubeVideo(id) {
    const params = querystring.stringify({
        id: id,
        key: GOOGLE_API_KEY,
        part: "snippet,contentDetails,statistics",
    });
    //영상아이디 리스트로 영상정보가져오기 (재생시간,시청수 때문)
    //const url = `https://www.googleapis.com/youtube/v3/videos?${params}`;
    const url = `${ROOT_URL}/${global.bj_id}/sns/youtube/videos?${params}`;
    return fetchJsonp(url)
        .then((response) => response.json())
        .then((response) => {
            if (response.error) {
                return Promise.reject(response.error);
            }
            return response;
        });
}

/**
 * 유투브 채널의 구독자수
 * @param {string} id
 */
export function fetchYoutubeFollowed(id) {
    return (dispatch) => {
        const followKey = `youtube_followd_by_${id}`;
        const followers = sessionStorage.getItem(followKey);
        if (followers !== null) {
            dispatch({ type: SET_YOUTUBE_FOLLOWED, followers });
            return;
        }

        const params = querystring.stringify({
            id: id,
            key: GOOGLE_API_KEY,
            part: "statistics",
        });

        //const url = `https://www.googleapis.com/youtube/v3/channels?${params}`;
        const url = `${ROOT_URL}/${global.bj_id}/sns/youtube/channels?${params}`;

        return fetch(url)
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                if (!response.items[0]) {
                    return Promise.reject("response.items is undefined");
                }
                if (response.items[0].statistics.hiddenSubscriberCount) {
                    return Promise.reject("hiddenSubscriberCount");
                }
                return response;
            })
            .then((response) => {
                const followers = response.items[0].statistics.subscriberCount;
                console.log("followers", followers);
                dispatch({ type: SET_YOUTUBE_FOLLOWED, followers });
                sessionStorage.setItem(followKey, followers);
            })
            .catch((error) => {
                console.log("followers error", error);
            });
    };
}

/**
 * 유튜브 embed
 * @param {*} token
 */
export function fetchYoutubeOEmbed(media_id) {
    //https://www.youtube.com/oembed?url=http%3A//www.youtube.com/watch%3Fv%3DbDOYN-6gdRE&format=json
    const params = querystring.stringify({
        url: `https://www.youtube.com/watch?v=${media_id}`,
        format: "json",
        callback: "test",
    });
    return fetch(`//www.youtube.com/oembed?${params}`).then((response) => response.json());
}
