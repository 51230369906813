import axios from "libs/axios";
import { ROOT_URL } from "actions/config";
import qs from "qs";
import {SELECTED_ALL} from "constant/parameter";
import {toastr} from "components/toastr";
import i18next from "i18next";


export const FETCH_SETTING_SHOPFREECA_LIST = 'FETCH_SETTING_SHOPFREECA_LIST';
export const FETCH_SETTING_SHOPFREECA_LIST_SUCCESS = 'FETCH_SETTING_SHOPFREECA_LIST_SUCCESS';
export const FETCH_SETTING_SHOPFREECA_LIST_FAIL = 'FETCH_SETTING_SHOPFREECA_LIST_FAIL';
export const UPDATE_SETTING_SHOPFREECA_LIST = 'UPDATE_SETTING_SHOPFREECA_LIST';
export const DELETE_SETTING_SHOPFREECA = 'DELETE_SETTING_SHOPFREECA';
export const DELETE_ALL_SETTING_SHOPFREECA = 'DELETE_ALL_SETTING_SHOPFREECA';

export const FETCH_SETTING_ADD_SHOPFREECA_SUCCESS = 'FETCH_SETTING_ADD_SHOPFREECA_SUCCESS';
export const FETCH_SETTING_ADD_SHOPFREECA_FAIL = 'FETCH_SETTING_ADD_SHOPFREECA_FAIL';

export const FETCH_SETTING_SHOPFREECA_RELOAD = 'FETCH_SETTING_SHOPFREECA_RELOAD';
export const UPDATE_SETTING_SHOPFREECA_SORT = 'UPDATE_SETTING_SHOPFREECA_SORT';

export const SELETE_SETTING_CHECK_SHOPFREECA_SUCCESS = 'SELETE_SETTING_CHECK_SHOPFREECA_SUCCESS';

export const FETCH_SETTING_MEMBER_CHECK_SUCCESS = "FETCH_SETTING_MEMBER_CHECK_SUCCESS";
export const FETCH_SETTING_MEMBER_CHECK_FAIL = "FETCH_SETTING_MEMBER_CHECK_FAIL";

// 방송국 설정 > 샵프리카 영역 관리 > BJ 가 설정한 샵프리카 상품 리스트 노출 페이지
export const fetchSettingShopfreeca = () => async (dispatch) => {

    // 로딩 시작
    dispatch({ type: FETCH_SETTING_SHOPFREECA_LIST });

    // 샵프리카 리스트 조회 url
    let url = `/${ROOT_URL}/${global.bj_id}/setting/shopfreeca`;


    try {
        // api 통신 시작
        const response = await axios.get(url);

        /*if (response.curation_product.length <= 0) {
            throw new Error();
        }*/

        dispatch({
            type: FETCH_SETTING_SHOPFREECA_LIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SETTING_SHOPFREECA_LIST_FAIL,
            payload: error,
        });
    }
};

// 방송국 설정 > 샵프리카 영역 관리 > BJ가 상품 정렬 시 API 요청
export const storeSettingSortShopfreeca = (param) => async (dispatch) => {
    // 샵프리카 리스트 조회 url
    let url = `/${ROOT_URL}/${global.bj_id}/setting/shopfreeca/sort`;


    try {
        // api 통신 시작
        const response = await axios.post(url, qs.stringify(param));

        toastr.success(i18next.t(response.message), {
            timeOut: 2000,
        });

        dispatch({ type: UPDATE_SETTING_SHOPFREECA_SORT });

    } catch (error) {
        dispatch({
            type: FETCH_SETTING_SHOPFREECA_LIST_FAIL,
            payload: error,
        });
    }

}

export const fetchShopfreecaAddList = ({orderBy = 'reg_date', type = 'marpple'}) => async (dispatch) => {

    // 샵프리카 리스트 조회 url
    let url = `/${ROOT_URL}/${global.bj_id}/setting/shopfreeca/add`;

    const params = {
        orderBy: orderBy,
        type: type
    };

    // 로딩 시작
    dispatch({ type: FETCH_SETTING_SHOPFREECA_LIST });

    try {
        // api 통신 시작
        const response = await axios.get(url, { params });

        dispatch({
            type: FETCH_SETTING_ADD_SHOPFREECA_SUCCESS,
            payload: {...response, orderBy},
        });
    } catch (error) {
        dispatch({
            type: FETCH_SETTING_ADD_SHOPFREECA_FAIL,
            payload: error,
        });
    }
}

export const storeShopfreecaAdd = (param, storeArr, storeProductArr) => async (dispatch) => {

    let url = `/${ROOT_URL}/${global.bj_id}/setting/shopfreeca/add`;

    try {
        const response  = await axios.post(url, qs.stringify(param));
        await dispatch({
            type: FETCH_SETTING_SHOPFREECA_RELOAD,
            payload : {
                checkedProductArr : storeArr
            }
        });

        toastr.success(i18next.t(response.message), {
            timeOut: 2000,
        });

    } catch (error) {
        dispatch({
            type: FETCH_SETTING_ADD_SHOPFREECA_FAIL,
            payload: error,
        });
    }
}


export const deleteShopfreeca = (type, productNo = 0) => async (dispatch) => {
    try {
        let response;
        if (type === SELECTED_ALL) {
            // 전체 삭제
            response  = await axios.delete(`/${ROOT_URL}/${global.bj_id}/setting/shopfreeca/add/${productNo}`);
            await dispatch({ type: DELETE_ALL_SETTING_SHOPFREECA });

        } else {
            // 부분 삭제
            response  = await axios.delete(`/${ROOT_URL}/${global.bj_id}/setting/shopfreeca/${productNo}`);
            await dispatch({
                type: DELETE_SETTING_SHOPFREECA,
                payload: productNo,
            });
        }

        toastr.success(i18next.t(response.message), {
            timeOut: 2000,
        });

    } catch (error) {
        alert("샵프리카 상품 삭제 실패하였습니다");
        dispatch({
            type: FETCH_SETTING_ADD_SHOPFREECA_FAIL,
            payload: error,
        });
    }
}

export const seleteSettingCheckProduct = (checkedTempProduct) => ({
    type: SELETE_SETTING_CHECK_SHOPFREECA_SUCCESS,
    payload: {
        checkedTempProduct: checkedTempProduct
    }
});

// 마플 권한 체크
export const fetchMemberCheck = () => async (dispatch) => {
    const url = `https://shopfreeca.afreecatv.com/api/marpple/client/memberCheck`;

    try {
        // api 통신 시작
        const response = await axios.get(url);
        dispatch({
            type: FETCH_SETTING_MEMBER_CHECK_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SETTING_MEMBER_CHECK_FAIL,
            payload: error,
        });
    }
};