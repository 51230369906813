import querystring from "querystring";
import cookie from "react-cookies";
import i18next from "i18next";
import { ROOT_URL } from "./config";
import { UCC } from "../constant/board";
import { POST, DELETE } from "../constant/method";
import { toastr } from "../components/toastr";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";

export const HOME_DID_MOUNT = "HOME_DID_MOUNT";
export const HOME_WILL_UNMOUNT = "HOME_WILL_UNMOUNT";

export const MOBILE_FLOATING_OEPN = "MOBILE_FLOATING_OEPN";
export const MOBILE_FLOATING_CLOSE = "MOBILE_FLOATING_CLOSE";

export const FETCH_HOME = "FETCH_HOME";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAILURE = "FETCH_HOME_FAILURE";

//홈에고정 추가
export const STORE_PIN = "STORE_PIN";
export const STORE_PIN_SUCCESS = "STORE_PIN_SUCCESS";

export const DESTORY_PIN = "DESTORY_PIN";
export const DESTORY_PIN_SUCCESS = "DESTORY_PIN_SUCCESS";

export const STORE_PIN_BOTTOM_SUCCESS = "STORE_PIN_BOTTOM_SUCCESS";
export const DESTORY_PIN_BOTTOM_SUCCESS = "DESTORY_PIN_BOTTOM_SUCCESS";

export const COMMENTING = "COMMENTING";

export function commenting(flag) {
	return (dispatch) => {
		dispatch({
			type: COMMENTING,
			payload: flag,
		});
	};
}

export function homeDispatch(type) {
	return (dispatch) => {
		dispatch({ type });
	};
}
export function fetchHome() {
	return (dispatch) => {
		dispatch({ type: FETCH_HOME });

		fetch(`${ROOT_URL}/${global.bj_id}/home`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_HOME_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_HOME_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}



/**
 * 홈에고정 추가
 * @param {*} bj_id
 */
export function storeHomePin(title_no, board_type) {
	return (dispatch) => {
		dispatch({ type: STORE_PIN });

		const checkUrl = `${ROOT_URL}/${global.bj_id}/home/pin?title_no=${title_no}&board_type=${board_type}`;

		//로딩
		fetch(checkUrl, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				if (response.is_pin === 0) {
					_realStoreHopin(dispatch, title_no, board_type);
				} else {
					const msg =
						board_type === UCC
							? i18next.t("이미 고정된 VOD가 있습니다. 변경하시겠습니까?")
							: i18next.t("이미 고정된 게시글이 있습니다. 변경하시겠습니까?");
					if (window.confirm(msg)) {
						_realStoreHopin(dispatch, title_no, board_type);
					}
				}
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}
const _realStoreHopin = (dispatch, title_no, board_type) => {
	//로딩
	fetch(`${ROOT_URL}/${global.bj_id}/home/pin`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify({ title_no, board_type, _token: cookie.load("XSRF-TOKEN") }),
		credentials: "include",
	})
		.then(handleFetchErrors, handleFailedToFetch)
		.then((response) => response.json())
		.then((response) => {
			dispatch({
				type: STORE_PIN_SUCCESS,
				payload: response,
			});

			dispatch({
				type: STORE_PIN_BOTTOM_SUCCESS,
				payload: response,
			});

			toastr.success(response.message, {
				timeOut: 2000,
			});
		})
		.catch((error) => {
			toastr.error(error.message, {
				timeOut: 2000,
			});
		});
};

/**
 * 홈에고정 삭제
 * @param {*} bj_id
 */
export function destroyHomePin(title_no, board_type) {
	return (dispatch) => {
		if (window.confirm(i18next.t("고정을 해제하시겠습니까?"))) {
			dispatch({ type: DESTORY_PIN });
			//로딩
			fetch(`${ROOT_URL}/${global.bj_id}/home/pin/${title_no}`, {
				method: POST,
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: querystring.stringify({ _method: DELETE, board_type, _token: cookie.load("XSRF-TOKEN") }),
				credentials: "include",
			})
				.then(handleFetchErrors, handleFailedToFetch)
				.then((response) => response.json())
				.then((response) => {
					dispatch({
						type: DESTORY_PIN_SUCCESS,
						payload: response,
					});

					dispatch({
						type: DESTORY_PIN_BOTTOM_SUCCESS,
						payload: response,
					});

					toastr.info(response.message);
				})
				.catch((error) => {
					toastr.error(error.message, {
						timeOut: 2000,
					});
				});
		}
	};
}
