import cookie from "react-cookies";
import querystring from "querystring";
import i18next from "i18next";
import axios from "axios";

import { handleFetchErrors, handleFailedToFetch } from "helpers";

import { ROOT_URL } from "actions/config";

import { toastr } from "components/toastr";

import { POST, DELETE } from "constant/method";

export const SETP1_IMAGE_SELECT = 1;
export const SETP2_IMAGE_PROGRESS = 2;
export const SETP3_SELECT_IMAGE_AREA = 3;
export const SETP4_SELECT_COLOR = 4;
export const SETP5_COMPLETE = 5;
//Delete post
export const CHANNELART_CHANGE_STEP = "CHANNELART_CHANGE_STEP";
export const FETCH_CHANNELART_SUCCESS = "FETCH_CHANNELART_SUCCESS";

export const CHANNELART_IMAGE_UPLOAD = "CHANNELART_IMAGE_UPLOAD";
export const CHANNELART_IMAGE_UPLOAD_PROGRESS = "CHANNELART_IMAGE_UPLOAD_PROGRESS";
export const CHANNELART_IMAGE_UPLOAD_SUCCESS = "CHANNELART_IMAGE_UPLOAD_SUCCESS";
export const CHANNELART_IMAGE_UPLOAD_FAILURE = "CHANNELART_IMAGE_UPLOAD_FAILURE";
export const CHANNELART_IMAGE_DELETE = "CHANNELART_IMAGE_DELETE";
export const CHANNELART_CROP_COMPLETE = "CHANNELART_CROP_COMPLETE";
export const CHANNELART_SAVE_COMPLETE = "CHANNELART_SAVE_COMPLETE";
export const CHANNELART_RESET = "CHANNELART_RESET";

export const CHANNELART_INIT_SUCCESS = "CHANNELART_INIT_SUCCESS";
export const CHANNELART_INIT_FAILURE = "CHANNELART_INIT_FAILURE";
export const CHANNELART_COLOR_CLICK = "CHANNELART_COLOR_CLICK";

export function fetchChannelArt() {
	return (dispatch) => {
		//로딩
		fetch(`${ROOT_URL}/${global.bj_id}/setting/channelart`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_CHANNELART_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: CHANNELART_IMAGE_UPLOAD_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function channelArtUpload(file, form) {
	return (dispatch) => {
		dispatch({
			type: CHANNELART_CHANGE_STEP,
			step: SETP2_IMAGE_PROGRESS,
		});
		dispatch({
			type: CHANNELART_IMAGE_UPLOAD,
			filename: file.name,
		});
		const data = new FormData();
		data.append("file", file);
		data.append("_token", cookie.load("XSRF-TOKEN"));
		//로딩
		//로딩
		axios({
			method: "post",
			data,
			url: `${ROOT_URL}/${global.bj_id}/setting/channelart/image`,
			withCredentials: true,
			onUploadProgress(progressEvent) {
				dispatch({
					type: CHANNELART_IMAGE_UPLOAD_PROGRESS,
					progress: Math.floor((progressEvent.loaded * 100) / progressEvent.total),
				});
			},
		}).then(
			(response) => {
				dispatch({
					type: CHANNELART_IMAGE_UPLOAD_SUCCESS,
					payload: response.data,
				});
				dispatch({
					type: CHANNELART_CHANGE_STEP,
					step: SETP3_SELECT_IMAGE_AREA,
				});
			},
			(error) => {
				dispatch({
					type: CHANNELART_IMAGE_UPLOAD_FAILURE,
					payload: error.response ? error.response.data : { code: 0, message: error.message },
				});
			},
		);
	};
}

export function channelArtCrop(key, crop) {
	return (dispatch) => {
		//로딩
		fetch(`${ROOT_URL}/${global.bj_id}/setting/channelart/crop`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ key, crop: JSON.stringify(crop), _token: cookie.load("XSRF-TOKEN") }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: CHANNELART_CROP_COMPLETE,
					payload: response,
				});
				dispatch({
					type: CHANNELART_CHANGE_STEP,
					step: SETP4_SELECT_COLOR,
				});
			})
			.catch((error) => {
				dispatch({
					type: CHANNELART_IMAGE_UPLOAD_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function channelArtSave(pc, mobile, color) {
	return (dispatch) => {
		//로딩
		fetch(`${ROOT_URL}/${global.bj_id}/setting/channelart/save`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({
				pc: JSON.stringify(pc),
				mobile: JSON.stringify(mobile),
				color: color,
				_token: cookie.load("XSRF-TOKEN"),
			}),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: CHANNELART_SAVE_COMPLETE,
					payload: response,
				});
				dispatch({
					type: CHANNELART_CHANGE_STEP,
					step: SETP5_COMPLETE,
				});
			})
			.catch((error) => {
				dispatch({
					type: CHANNELART_IMAGE_UPLOAD_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function channelArtReset() {
	return (dispatch) => {
		//데이터제거
		dispatch({
			type: CHANNELART_RESET,
		});
		//1번째 스텝으로 변경
		dispatch({
			type: CHANNELART_CHANGE_STEP,
			step: SETP1_IMAGE_SELECT,
		});
	};
}

//타이틀 이미지 초기화
export function channelArtInit() {
	return (dispatch) => {
		fetch(`${ROOT_URL}/${global.bj_id}/setting/channelart/1`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
			credentials: "include",
		})
			.then(handleFetchErrors)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: CHANNELART_INIT_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: CHANNELART_INIT_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

//모바일에서 상단바 색상 선택
export function channelArtColorClick(color) {
	return (dispatch) => {
		dispatch({
			type: CHANNELART_COLOR_CLICK,
			payload: color,
		});
	};
}

//모바일에서 상단바 색상 저장
export const channelArtColorSave = (color) => {
	return (dispatch) => {
		//로딩
		fetch(`${ROOT_URL}/${global.bj_id}/setting/channelart/save`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({
				color: color,
				_token: cookie.load("XSRF-TOKEN"),
			}),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: CHANNELART_SAVE_COMPLETE,
					payload: response,
				});
				toastr.error(i18next.t("상단바 색상이 변경되었습니다."), {
					timeOut: 3000,
				});
			})
			.catch((error) => {
				toastr.error(i18next.t("색상 변경에 실패하였습니다."), {
					timeOut: 3000,
				});
			});
	};
}
