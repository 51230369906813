import React, { useCallback, useEffect, useMemo } from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    isMobile,
    osVersion,
    browserVersion,
    mobileModel,
    isAndroid,

} from "react-device-detect";
import {fetchSaybuzz} from "actions/ad/saybuzz.action.js";
import {getSaybuzzParams} from "constant/ad";
import { getPlatForm, getAppVersion, isWebview } from "helpers";
import cookie from "react-cookies";

export const SaybuzzAds = ({ code }) => {

    const dispatch = useDispatch();
    const { data = {} , error } = useSelector((state) => state.saybuzzReducer, shallowEqual);
    const { campaign_id = "personal_sponsorship",  space_id= '',  log = {},  image_data = {} , mobile_sub_data = {}, mobile_main_data = {}  } = data;
    const { click_url = ''} = log;
    
    const { background_color: image_bgr_color = "#FFFFFF", image_url = '',
        space_element_name: image_name = '', space_element_type : image_type = 'image', text: image_txt = '' } = image_data; // PC, Mobile 이미지
    
    // 모바일의 경우 array space_element(3) 으로 내려와 (0) 이미지, (1) 메인 타이틀, (2) 서브 타이틀 순으로 데이터 내려줌 
    const { background_color: mob_sub_bgr_color = "#FFFFFF", space_element_name : mob_sub_name = '', 
        space_element_type: mob_sub_type = 'image', text : mob_sub_txt = '' } = mobile_sub_data; // PC, Mobile 이미지
    
    const { background_color: mob_main_bgr_color = "#FFFFFF", space_element_name: mob_main_name = '', 
        space_element_type: mob_main_type = 'image', text: mob_main_txt = '' } = mobile_main_data; // PC, Mobile 이미지

    const handleLinkUrl = useCallback(() => {

        if (isWebview) {
            if (isAndroid) {
                window.open(`afreeca://browser/station?url=${encodeURIComponent(click_url)}`, "saybuzz", "width=508,height=636");
            } else {
                window.open(click_url, "_blank", "width=508,height=636");
            }
            
        } else {
            window.open(click_url);
            //window.location.href = click_url;
        }
    }, [click_url]);
    

    const handleLog = () => {

    };

    // PdBoxUser 쿠키조회
    const getCookieParse = useCallback((key) => {
        let szPdboxUser = cookie.load("PdboxUser");
        if (szPdboxUser == null) return;
        try {
            let aPdboxUser = decodeURIComponent(szPdboxUser).split("&");
            let aVal = aPdboxUser
                .map(function(data) {
                    return data.split("=");
                })
                .filter(function(data) {
                    return data[0] === key;
                });
            return aVal[0][1];
        } catch (e) {
            return;
        }
    }, []);

    // 나이 계산
    const getUser = useCallback(() => {
        let user = '0000';
        const sex = getCookieParse("sex");
        const age = getCookieParse("age");

        // NOCUSTOMIZEDAD: 맞춤형 허용 여부 '차단' 설정자인 경우 와 만 14세 미만 경우 생성규칙 고정
        if (cookie.load("PdboxUser") !== null &&
            cookie.load("NOCUSTOMIZEDAD") !== 1 &&
            (sex !== undefined && sex !== "C") &&
            age !== undefined
        ) {
            //성별
            if (sex === "A") {
                user = "Y"; //남자
            } else if (sex === "B") {
                user = "X"; //여자
            } else {
                user = "Z"; //기타
            }

            //10진수 > 8진수값 변환 (ex. 14-18세 1622)
            if (14 <= age && age <= 18) {
                user += Number(14).toString(8) + Number(18).toString(8);
            } else if (19 <= age && age <= 24) {
                user += Number(19).toString(8) + Number(24).toString(8);
            } else if (25 <= age && age <= 29) {
                user += Number(25).toString(8) + Number(29).toString(8);
            } else if (30 <= age && age <= 34) {
                user += Number(30).toString(8) + Number(34).toString(8);
            } else if (35 <= age && age <= 39) {
                user += Number(35).toString(8) + Number(39).toString(8);
            } else if (40 <= age && age <= 44) {
                user += Number(40).toString(8) + Number(44).toString(8);
            } else if (45 <= age && age <= 49) {
                user += Number(45).toString(8) + Number(49).toString(8);
            } else if (50 <= age && age <= 54) {
                user += Number(50).toString(8) + Number(54).toString(8);
            } else if (55 <= age && age <= 55) {
                user += Number(55).toString(8) + Number(55).toString(8);
            } else if (60 <= age) {
                user += Number(60).toString(8) + Number(60).toString(8);
            } else {
                user = "0000";
            }
        }

        return user;
    }, [getCookieParse]);

    useEffect(() => {

        const {campaign: campaignId, space: spaceId} = getSaybuzzParams(code, isMobile);
        const unk = "UNKNOWN";
        const deviceWidth = window.screen.width || unk;
        const deviceHeight = window.screen.height || unk;
        const userLang = navigator.language || navigator.userLanguage || "";
        const aftvUserId = cookie.load("_au");

        const params = {
            campaign_id: campaignId,
            space_id: spaceId,
            user_id: global.bj_id,    //개인 참여형 (BJ 유저 고유번호 ex. campaign_id=personal_sponsership 필수)
            resolution_height: deviceHeight,  // 세로 해상도
            resolution_width:  deviceWidth,   // 가로 해상도
            user: getUser(),   // 성별 + 나이
            device: isMobile ? mobileModel : unk, //기기 모델명
            browser_ver : browserVersion, // 브라우저 버전
            app_ver : getAppVersion(), // 앱 버전
            application : "WEB", // 플랫폼 구분
            os_ver: osVersion, // os 버전
            os: getPlatForm() || unk, // os 정보 (windows, mac, android, ios, unknown)
            acpt_lang: userLang || unk, // 서비스 언어 (ko_KR)
            //user_ip: '', // 유저 IP
            //geo_rc: '', //지역 코드
            //geo_cc: country, //국가 코드
        };

        dispatch(fetchSaybuzz(params));
    }, [code, dispatch, getUser]);


    if (image_url === '') {
        return null;
    }

    if (isMobile) {
        return (
            <div className="bnr_wrap2" onClick={handleLog()} >
                <a onClick={handleLinkUrl}>
                    <span className="img"><img src={image_url} alt="" /></span>
                    <div className="text">
                        <em style={{backgroundColor: image_bgr_color}} >{mob_sub_txt}</em>
                        <p style={{backgroundColor: image_bgr_color}}>{mob_main_txt}</p>
                    </div>
                    <span className="btn">바로가기</span>
                </a>
            </div>
        );
    } else {
        return (
            <div className="bnr_wrap2" onClick={handleLog()} >
                <a href={click_url} target="_blank"><img src={image_url} alt="" /></a>
            </div>
        );
    }
}

export default SaybuzzAds;
