import {fromJS, List, Map} from "immutable";
import { INIT_PROFILE_IMAGE_SUCCESS, PUT_PROFILE_IMAGE_SUCCESS, PUT_USER_NICK } from "../actions/setting.station.action";
import {
    DESTROY_FAVORITE_PUSH_SUCCESS,
    DESTROY_FAVORITE_SUCCESS,
    FETCH_BJ_SUCCESS,
    FETCH_DETAIL,
    FETCH_DETAIL_FAILURE,
    FETCH_DETAIL_SUCCESS,
    FETCH_FAVORITE,
    FETCH_FAVORITE_FAILURE,
    FETCH_FAVORITE_SUCCESS,
    FETCH_NOTICE_SUCCESS,
    FETCH_STATION,
    FETCH_STATION_FAILURE,
    FETCH_STATION_SUCCESS,
    PUT_PROFILE_TEXT_SUCCESS,
    REACT_POPUP_OPEN,
    SET_PRIVATE_STATE,
    SET_INSTAGRAM_FOLLOWED,
    SET_YOUTUBE_FOLLOWED,
    STORE_FAVORITE_PUSH_SUCCESS,
    STORE_FAVORITE_SUCCESS,
} from "../actions/station.action";

const INITIAL_STATE = {
    reactPopupId: null,
    stationInfo: { error: null, loading: true },
    bjInfo: { medals: [], links: [] },
    favoiteListInfo: { error: null, loading: true },
    detailInfo: { error: null, loading: true },
    noticeInfo: [],
};

export default function stationReducer(state = INITIAL_STATE, action) {
    let error;
    switch (action.type) {
        case REACT_POPUP_OPEN:
            // console.log("REACT_POPUP_OPEN", action.id);
            return {
                ...state,
                reactPopupId: action.id,
            };
        case FETCH_STATION:
            //관리페이지 -> 홈 이동시 업데이트 시키지 않음
            if (state.stationInfo.data) {
                return state;
            } else {
                return {
                    ...state,
                    stationInfo: { error: null, loading: true },
                };
            }
        case FETCH_STATION_SUCCESS:
            return {
                ...state,
                stationInfo: { data: action.payload, error: null, loading: false },
            };
        case FETCH_STATION_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return {
                ...state,
                stationInfo: { error, loading: false },
            };

        case FETCH_BJ_SUCCESS:
            ////console.log(FETCH_BJ_SUCCESS);
            return {
                ...state,
                bjInfo: action.payload,
            };

        /**
         * 즐겨찾기 리스트
         */
        case FETCH_FAVORITE:
            return {
                ...state,
                favoiteListInfo: { error: null, loading: true },
            };
        case FETCH_FAVORITE_SUCCESS:
            return {
                ...state,
                favoiteListInfo: { data: action.payload, error: null, loading: false },
            };
        case FETCH_FAVORITE_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return {
                ...state,
                favoiteListInfo: { error, loading: false },
            };

        /**
         * 즐찾추가
         */
        case STORE_FAVORITE_SUCCESS:
            return {
                ...state,
                stationInfo: { ...state.stationInfo, data: { ...state.stationInfo.data, is_favorite: true, is_mobile_push: true } },
            };
        /**
         * 즐찾해제
         */
        case DESTROY_FAVORITE_SUCCESS:
            return {
                ...state,
                stationInfo: { ...state.stationInfo, data: { ...state.stationInfo.data, is_favorite: false, is_mobile_push: false } },
            };
        /**
         * 즐찾 푸시 추가
         */
        case STORE_FAVORITE_PUSH_SUCCESS:
            return {
                ...state,
                stationInfo: { ...state.stationInfo, data: { ...state.stationInfo.data, is_mobile_push: true } },
            };
        /**
         * 즐찾 푸시 삭제
         */
        case DESTROY_FAVORITE_PUSH_SUCCESS:
            return {
                ...state,
                stationInfo: { ...state.stationInfo, data: { ...state.stationInfo.data, is_mobile_push: false } },
            };

        /**
         * 랭킹
         */
        case FETCH_DETAIL:
            return {
                ...state,
                detailInfo: { error: null, loading: true },
            };
        case FETCH_DETAIL_SUCCESS:
            return {
                ...state,
                detailInfo: { payload: action.payload, error: null, loading: false },
            };
        case FETCH_DETAIL_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return {
                ...state,
                detailInfo: { error, loading: false },
            };
        case PUT_PROFILE_IMAGE_SUCCESS:
            return Map(fromJS(state))
                .updateIn(["stationInfo", "data", "profile_image"], () => action.payload.data.profile_image)
                .toJS();
        case PUT_USER_NICK:
            return Map(fromJS(state))
                .updateIn(["stationInfo", "data", "station", "user_nick"], () => action.payload)
                .toJS();
        case PUT_PROFILE_TEXT_SUCCESS:
            return Map(fromJS(state))
                .updateIn(["stationInfo", "data", "station", "display", "profile_text"], () => action.payload.profile_text)
                .toJS();

        case FETCH_NOTICE_SUCCESS:
            return {
                ...state,
                noticeInfo: action.payload,
            };
        case INIT_PROFILE_IMAGE_SUCCESS:
            return Map(fromJS(state))
                .updateIn(["stationInfo", "data", "profile_image"], () => action.payload.data.profile_image)
                .toJS();

        /**
         * 유튜브 팔로워수 셋팅
         */
        case SET_YOUTUBE_FOLLOWED: {
            const newState = Map(fromJS(state));
            const index = newState.getIn(["stationInfo", "data", "station", "sns"]).findIndex((item) => {
                return Map(item).get("type") === 1;
            });
            return newState.setIn(["stationInfo", "data", "station", "sns", index, "followers"], action.followers).toJS();
        }

        /**
         * 인스크램 팔로워수 및 비공개 여부 셋팅
         */
        case SET_INSTAGRAM_FOLLOWED: {
            const oldState = fromJS(state);
            const _data = List(oldState.getIn(["stationInfo", "data", "station", "sns"]));
            return oldState.setIn(
                ["stationInfo", "data", "station", "sns"],
                _data.update(
                    _data.findIndex((sns) => {
                        return Map(sns).get("type") === 2;
                    }),
                    (sns) =>
                        Map(sns)
                            .update("followers", () => action.followers)
                            //.update("isPrivate", () => false),

                ),
            ).toJS();
        }

        /**
         * 인스크램 비공개 여부 셋팅
         */
        case SET_PRIVATE_STATE: {
            const newState = Map(fromJS(state));
            const index = newState.getIn(["stationInfo", "data", "station", "sns"]).findIndex((item) => {
                return Map(item).get("type") === 2;
            });
            return newState.setIn(["stationInfo", "data", "station", "sns", index, "isPrivate"], action.isPrivate).toJS();
        }
        default:
            return state;
    }
}
