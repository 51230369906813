import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { fetchFavorites } from "../../actions/station.action";
import { AFREECATV_URL, FAVORITE_URL } from "../../constant/config";
import ScrollArea from "../scrollbar/ScrollArea";

class Favorites extends Component {
	componentDidMount() {
		const { favoiteListInfo } = this.props;
		const { data = [] } = favoiteListInfo;
		if (data.length === 0) {
			this.props.fetchFavorites();
		}
	}
	render() {
		const { favoiteListInfo } = this.props;
		const { data = [] } = favoiteListInfo;

		const scrollbarStyles = { borderRadius: 5 };
		return (
			<div
				className="layer_bs-favor"
				style={{ position: "absolute", top: 39, right: -7 }}
				onClick={(e) => {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();
				}}
			>
				<div className="head">
					<h3>
						<Trans>즐겨찾기</Trans>
					</h3>
					<button type="button" className="adm" onClick={() => window.open(FAVORITE_URL)}>
						<span>
							<Trans>즐겨찾기 편집</Trans>
						</span>
					</button>
				</div>

				<div className="favor-list">
					<ScrollArea
						speed={2}
						className="area"
						contentClassName="content"
						verticalScrollbarStyle={scrollbarStyles}
						verticalContainerStyle={scrollbarStyles}
						horizontalScrollbarStyle={scrollbarStyles}
						horizontalContainerStyle={scrollbarStyles}
						vertical
						horizontal={false}
						smoothScrolling
						stopScrollPropagation={true}
					>
						<ul>
							{data.length === 0 && (
								<li className="non">
									<Trans>즐겨찾기 한 BJ가 없습니다</Trans>.
								</li>
							)}
							{data.map((favorite, index) => (
								<li key={index} className={classNames({ live: favorite.is_live })}>
									<a href={`${AFREECATV_URL}/${favorite.user_id}`} target="_target">
										<p>{favorite.nickname}</p>
									</a>
								</li>
							))}
						</ul>
					</ScrollArea>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		favoiteListInfo: state.stationReducer.favoiteListInfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchFavorites: () => {
			dispatch(fetchFavorites());
		},
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Favorites);
