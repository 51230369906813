import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import classNames from "classnames";

import { searchLog } from "libs/searchLogCollection";

import { appendCSk, fetchSearchHotKeyword, fetchSearchRecommendKeyword } from "actions/afreeca.search.action";

import { ALL, TITLE_CONTENTS } from "constant/search";
import { ACTTYPE_TOTAL, STYPE_DIRECT } from "constant/log";

import AutoCompleteBox from "components/header/search/AutoCompleteBox";
import withParams from "components/hoc/withParams";

class AfreecaSearchBar extends Component {
	constructor(props) {
		super(props);

		const { keyword = "" } = this.props.getParams();
		this.state = {
			keyword: keyword,
			autoCompleteKeyword: "",
			autoComplete: false,
			keyMoveIndex: 0,
			schBtnOn: false,
		};
		this.nonBlurEvent = false;
	}

	/**
	 * 검색창 keydown
	 * @param e
	 */
	handleKeyDown = (e) => {
		const { keyCode } = e;

		// 자동완성 선택 값 세팅
		if (keyCode === 38) {
			this.setState((prevState) => ({ keyMoveIndex: prevState.keyMoveIndex - 1 }));
		} else if (keyCode === 40) {
			this.setState((prevState) => ({ keyMoveIndex: prevState.keyMoveIndex + 1 }));
		}
	};

	/**
	 * 검색창 change (키워드 변경)
	 * @param event
	 */
	handleChangeKeyword = (event) => {
		const keyword = event.target.value;
		this.setState({
			keyword,
			autoCompleteKeyword: "",
			autoComplete: keyword === "",
		});
	};

	/**
	 * 검색창 keyup
	 * @param e
	 */
	handleKeyUp = (e) => {
		const { keyword } = this.state;
		const { keyCode } = e;

		// enter, 상하좌우 키가 아닐 경우 자동완성 세팅
		if (keyword === "" && (keyCode !== 13 && !(keyCode >= 37 && keyCode <= 40))) {
			this.setState({ autoComplete: true });
			this.handleLoadAutoComplete(keyword);
		}
	};

	/** 검색창 focus */
	handleFocus = () => {
		const { keyword } = this.state;
		// 자동완성 세팅
		if (keyword === "") {
			this.setState({ autoComplete: true });
			this.handleLoadAutoComplete();
		}
	};

	/** 검색창 blur (focus out) */
	handleBlur = () => {
		if (this.nonBlurEvent === false) {
			const { autoCompleteKeyword = "", keyword = "" } = this.state;
			this.setState({
				autoComplete: false,
				autoCompleteKeyword: "",
				keyword: autoCompleteKeyword || keyword,
			});
		}
	};

	/**
	 * 자동완성 데이터 호출
	 * @param keyword
	 */
	handleLoadAutoComplete(keyword) {
		if (keyword === "" || keyword === undefined) {
			this.props.fetchSearchHotKeyword();
		}
	}

	/**
	 * 자동완성 검색어 클릭 이벤트
	 * @param keyword 선택한 검색어
	 */
	handleAutoCompleteClick = (keyword) => {
		this.setState(
			{
				autoComplete: false,
				autoCompleteKeyword: "",
				keyword,
			},
			() => {
				this.keywordRef.focus();
			},
		);
	};

	/**
	 *
	 * @param keyword
	 * @param bClose
	 */
	handleAutoCompletChange = (keyword, bClose = false) => {
		this.setState({
			autoCompleteKeyword: keyword,
			autoComplete: !bClose,
		});
	};

	/**
	 * 자동완성 레이어에 마우스가 있을경우 blur 되지 않도록
	 * @param over
	 */
	handleAutoCompleteOver = (over) => () => {
		this.nonBlurEvent = over;
	};

	/**
	 * 통합 검색
	 * @param e
	 * @returns {boolean}
	 */
	handleAfreecaSearch = (e) => {
		const { keyword = "", autoCompleteKeyword = "" } = this.state;
		const lastKeyword = autoCompleteKeyword || keyword;
		if (lastKeyword === "") {
			alert(this.props.t("검색어를 입력해주세요."));
			e.preventDefault();
			return false;
		} else {
			appendCSk(lastKeyword);
			searchLog({ lastKeyword, filter: ALL, actType: ACTTYPE_TOTAL });
			return true;
		}
	};

	/**
	 * 통합검색 mouse over 여부
	 * @param over
	 * @returns {Function}
	 */
	handleTotalSearchOver = (over) => () => {
		this.setState({ schBtnOn: over });
	};

	/**
	 * 방송국 검색
	 * @param e
	 * @returns {boolean}
	 */
	handleSearch = (e) => {
		e.preventDefault();

		// 방송국 체크
		if (this.props.stationInfo.error) {
			alert("해당 방송국에서는 방송국 검색을 이용하실 수 없습니다.\n통합 검색을 이용해주세요.");
			return false;
		}

		// 검색어 체크
		const { historyParams } = this.props;
		const { keyword = "", autoCompleteKeyword = "" } = this.state;
		const lastKeyword = autoCompleteKeyword || keyword;
		if (!lastKeyword.trim()) {
			alert(this.props.t("검색어를 입력해주세요."));
			return false;
		}

		// 방송국 검색
		this.setState({ autoComplete: false, autoCompleteKeyword: "" }); // 자동 완성 off
		appendCSk(lastKeyword);
		searchLog({ keyword: lastKeyword, filter: TITLE_CONTENTS });
		historyParams( { keyword: lastKeyword, page: "1" }, { searchType: STYPE_DIRECT }, `/${global.bj_id}/search`);
	};

	render() {
		const { autoComplete, keyMoveIndex, keyword, autoCompleteKeyword } = this.state;
		const { t, afreecaSearchReducer } = this.props;
		const { keywords } = afreecaSearchReducer;
		const { data = [] } = keywords;
		const bAutoBoxOpen = autoComplete && data && data[0] !== ""; // auto complete 여부

		return (
			<div className={classNames("bs-search_box")}>
				<form
					action="//www.afreecatv.com/total_search.html"
					method="post"
					ref={(ref) => {
						this.form = ref;
					}}
					target="_blank"
				>
					<input type="hidden" name="szSearchType" value="total" />
					<input type="hidden" name="szStype" value="di" />
					<fieldset>
						<legend>검색</legend>
						<div className="srh_input">
							<div className="inp lately">
								<input
									type="text"
									name="szKeyword"
									autoComplete="off"
									onChange={this.handleChangeKeyword}
									onFocus={this.handleFocus}
									onKeyUp={this.handleKeyUp}
									onKeyDown={this.handleKeyDown}
									onBlur={this.handleBlur}
									value={autoCompleteKeyword || keyword}
									ref={(ref) => {
										this.keywordRef = ref;
									}}
									placeholder={t("방송국 검색")}
								/>
								{bAutoBoxOpen && (
									<AutoCompleteBox
										keywords={keywords}
										onMouseOver={this.handleAutoCompleteOver(true)}
										onMouseOut={this.handleAutoCompleteOver(false)}
										onMouseLeave={this.handleAutoCompleteOver(false)}
										onClick={this.handleAutoCompleteClick}
										onChange={this.handleAutoCompletChange}
										keyMoveIndex={keyMoveIndex}
										keyword={keyword}
									/>
								)}
								<button type="submit" className="btn-srh" tip={t("방송국 검색")} onClick={this.handleSearch}>
									<span>
										<Trans>검색</Trans>
									</span>
								</button>
							</div>
							<button
								type="submit"
								className={classNames("btn-tSrh", { on: this.state.schBtnOn })}
								onClick={this.handleAfreecaSearch}
								onMouseOver={this.handleTotalSearchOver(true)}
								onMouseOut={this.handleTotalSearchOver(false)}
							>
								<span>
									<Trans>통합검색</Trans>
								</span>
							</button>
						</div>
					</fieldset>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		afreecaSearchReducer: state.afreecaSearchReducer,
		stationInfo: state.stationReducer.stationInfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSearchHotKeyword: () => {
			dispatch(fetchSearchHotKeyword());
		},
		fetchSearchRecommendKeyword: (keyword) => {
			dispatch(fetchSearchRecommendKeyword(keyword));
		},
	};
};
export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(withParams(withTranslation()(AfreecaSearchBar))),
);
