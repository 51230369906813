import { fetchSns } from "actions/sns.action";
import { SubHeaderMobile } from "components/header/SubHeaderMobile";
import { INSTAGRAM, YOUTUBE } from "constant/sns";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import SnsInstagram from "./SnsInstagram";
import SnsYoutube from "./SnsYoutube";

export default function Sns() {
    const { t } = useTranslation();
    const [youtube, setYoutube] = useState();
    const [instagram, setInstagram] = useState();

    useEffect(() => {
        fetchSns().then((response) => {
            const youtube = response.find((sns) => sns.type === YOUTUBE);
            setYoutube(youtube);
            const instagram = response.find((sns) => sns.type === INSTAGRAM);
            setInstagram(instagram);
        });
    }, []);

    if (isMobile) {
        return (
            <div>
                <SubHeaderMobile title={t("SNS 연결 관리")} />
                <div id="bs-container">
                    <div id="bs-contents" className="adm">
                        <article className="sns_setting">
                            <div className="sns-info">
                                <SnsInstagram sns={instagram} />
                                {/*<SnsYoutube sns={youtube} />*/}
                            </div>
                            <ul className="warning_text">
                                <li style={{ lineHeight: 1.4 }}>

                                    {t("계정을 연결하시면 인스타그램 콘텐츠가 방송국 홈에 노출됩니다.")}
                                </li>
                                <li style={{ lineHeight: 1.4 }}>
                                    {t(
                                        "계정을 연결하시면 인스타그램 계정의 프로필, 이미지, 동영상을 아프리카TV에 제공하는 데 동의하는 것으로 간주합니다.",
                                    )}
                                    {t("(단, 개인정보는 수집하지 않습니다.)")}
                                </li>
                                <li style={{ lineHeight: 1.4 }}>
                                    {t(
                                        "인스타그램 계정이 비공개인 경우에도 계정 연결 시 이미지 및 동영상 썸네일이 방송국 홈에 일시적으로 노출되니 주의해주세요.",
                                    )}
                                    {t("(단, 클릭 시에는 비공개로 표시됩니다)")}
                                </li>
                                {/*
                                <li style={{ lineHeight: 1.4 }}>
                                    {t(
                                        "계정을 연결하시면 인스타그램 또는 유튜브 계정의 프로필, 이미지, 동영상을 아프리카TV에 제공하는 데 동의하는 것으로 간주합니다.",
                                    )}
                                    {t("(단, 개인정보는 수집하지 않습니다.)")}
                                </li>
                                <li style={{ lineHeight: 1.4 }}>
                                    {t(
                                        "인스타그램 계정이 비공개인 경우에도 계정 연결 시 이미지 및 동영상 썸네일이 방송국 홈에 일시적으로 노출되니 주의해주세요.",
                                    )}
                                    {t("(단, 클릭 시에는 비공개로 표시됩니다)")}
                                </li>

                                <li style={{ lineHeight: 1.4 }}>
                                    {t(
                                        "해당 유튜브 연결 기능에 사용되는 API 클라이언트는 YouTube API 서비스를 사용합니다. ",
                                    )}
                                </li>
                                <li style={{ lineHeight: 1.4 }}>
                                    {t("서비스 약관 및 개인정보 처리방침")}
                                    <p>- {t("유튜브")}&nbsp;:&nbsp;
                                        <a href="https://www.youtube.com/t/terms" target="_blank">
                                            {t(
                                                "유튜브 이용 약관"
                                            )}
                                        </a>, 
                                        <a href="https://policies.google.com/privacy" target="_blank">
                                            {t(
                                                "구글 개인정보 처리방침"
                                            )}
                                        </a>, 
                                        <a href="https://myaccount.google.com/permissions" target="_blank">
                                        {t(
                                            "연결된 서비스 관리"
                                        )}
                                        </a>
                                    </p>
                                </li>
                                */}
                            </ul>
                        </article>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <article id="contents" className="out_link">
                <div className="title_wrap">
                    <h2>{t("SNS 연결 관리")}</h2>
                </div>
                <div className="account_link">
                    <p className="info_text">{t("계정을 연결하시면 인스타그램 콘텐츠가 방송국 홈에 노출됩니다.")}</p>

                    <div className="sns-info">
                        <SnsInstagram sns={instagram} />
                        {/*<SnsYoutube sns={youtube} />*/}
                    </div>
                </div>
                <ul className="warning_text">
                    <li style={{ lineHeight: 1.4 }}>
                        {t(
                            "계정을 연결하시면 인스타그램 계정의 프로필, 이미지, 동영상을 아프리카TV에 제공하는 데 동의하는 것으로 간주합니다.",
                        )}
                        <br />
                        &nbsp;&nbsp;&nbsp;{t("(단, 개인정보는 수집하지 않습니다.)")}
                    </li>
                    <li style={{ lineHeight: 1.4 }}>
                        {t(
                            "인스타그램 계정이 비공개인 경우에도 계정 연결 시 이미지 및 동영상 썸네일이 방송국 홈에 일시적으로 노출되니 주의해주세요.",
                        )}
                        <br />
                        &nbsp;&nbsp;&nbsp;{t("(단, 클릭 시에는 비공개로 표시됩니다)")}
                    </li>
                    {/*
                    <li style={{ lineHeight: 1.4 }}>
                        {t(
                            "계정을 연결하시면 인스타그램 또는 유튜브 계정의 프로필, 이미지, 동영상을 아프리카TV에 제공하는 데 동의하는 것으로 간주합니다.",
                        )}
                        <br />
                        &nbsp;&nbsp;&nbsp;{t("(단, 개인정보는 수집하지 않습니다.)")}
                    </li>
                    <li style={{ lineHeight: 1.4 }}>
                        {t(
                            "인스타그램 계정이 비공개인 경우에도 계정 연결 시 이미지 및 동영상 썸네일이 방송국 홈에 일시적으로 노출되니 주의해주세요.",
                        )}
                        <br />
                        &nbsp;&nbsp;&nbsp;{t("(단, 클릭 시에는 비공개로 표시됩니다)")}
                    </li>

                    <li style={{ lineHeight: 1.4 }}>
                        {t(
                            "해당 유튜브 연결 기능에 사용되는 API 클라이언트는 YouTube API 서비스를 사용합니다. "
                        )}
                        <br />
                    </li>
                    <li style={{ lineHeight: 1.4 }}>
                        {t("서비스 약관 및 개인정보 처리방침")}
						<p>- {t("유튜브")}&nbsp;:&nbsp;
                            <a href="https://www.youtube.com/t/terms" target="_blank">
                                {t(
                                    "유튜브 이용 약관"
                                )}
                            </a>, 
                            <a href="https://policies.google.com/privacy" target="_blank">
                                {t(
                                    "구글 개인정보 처리방침"
                                )}
                            </a>, 
                            <a href="https://myaccount.google.com/permissions" target="_blank">
                            {t(
                                "연결된 서비스 관리"
                            )}
                            </a>
                        </p>
					</li>
					*/}
                </ul>
            </article>
        );
    }
}
